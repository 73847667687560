import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { SubscribeDtoTypeEnum } from 'api/generated';
import { CouponResponseDtoDiscountTypeEnum } from 'api/generated';
import useAsyncAction from 'hooks/useAsyncAction';
import { Dispatch, SetStateAction, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';

export const useCoupon = ({
  amount,
  courseId,
  subscribeType,
  term,
  defaultCouponCode,
  setObj,
  setDefCop,
  isCertificate,
  invoiceId,
  setClientSecret,
  setInvoiceId,
  isUpdate,
  isPurchase,
}: {
  amount: number;
  courseId: string;
  subscribeType: SubscribeDtoTypeEnum;
  term: TermEnum;
  defaultCouponCode: string;
  invoiceId: string;
  isCertificate: boolean;
  setObj?: any;
  setDefCop?: any;
  setClientSecret: Dispatch<SetStateAction<string | undefined>> | undefined;
  setInvoiceId: Dispatch<SetStateAction<string>>;
  isUpdate: boolean;
  isPurchase?: boolean;
}) => {
  const [checkPromoCode] = useAsyncAction(actions.courses.checkPromoCodeThunk);
  const [changePromoCode, isChangePromoCodeLoading] = useAsyncAction(actions.payments.changePromoCode);
  const [deletePromoCode, isLoading] = useAsyncAction(actions.payments.deletePromoCode);
  const useFirstMonthDiscount = useAppSelector(selectors.courses.selectUseFirstMonthDiscount);
  //@ts-ignore
  const [changeUseFirstCoupon, isCertificateLoading] = useAsyncAction(actions.payments.changeUseFirstPromoCode);
  const [defaultCoupon, setDefaultCoupon] = useState(defaultCouponCode);
  const [couponState, setCouponState] = useState({
    isSuccessCoupon: false,
    promoCode: defaultCouponCode || '',
    discountAmount: 0,
    discountValue: 0,
    couponErrorMessage: null,
  });
  const [isInvalidCoupon, setIsInvalidCoupon] = useState(false);

  const removeCouponForCertificate = async () => {
    if (!setClientSecret) {
      return;
    }
    const { clientSecret, invoiceId: newInvoiceId } = await changeUseFirstCoupon({
      promocode: '',
      invoiceId,
      useFirstMonthDiscount,
    });

    setClientSecret(clientSecret);
    setInvoiceId(newInvoiceId);
  };

  const removeCoupon = async () => {
    if (!setClientSecret) {
      return;
    }
    if (setObj && setDefCop) {
      setObj({});
      setDefCop(null);
    }
    setDefaultCoupon('');
    setCouponState({
      ...couponState,
      isSuccessCoupon: false,
      promoCode: '',
      discountAmount: 0,
      discountValue: 0,
    });
    if (!isCertificate) {
      const { clientSecret, invoiceId: newInvoiceId } = await deletePromoCode({ invoiceId });
      setClientSecret(clientSecret);
      setInvoiceId(newInvoiceId);
    } else {
      removeCouponForCertificate();
    }
  };

  const onCouponSubmit = async (eventOrData: React.BaseSyntheticEvent | { coupon?: string }) => {
    const data = eventOrData instanceof Event ? eventOrData.target.value : eventOrData;
    const couponCode = data.coupon || defaultCoupon;

    if (couponCode) {
      try {
        const res = await checkPromoCode({
          courseId: courseId,
          promocode: couponCode,
          //@ts-ignore
          paymentPeriod: term.toLocaleLowerCase(),
          level: subscribeType,
        });

        const isDiscountTypePercentage = res.coupon.discountType === CouponResponseDtoDiscountTypeEnum.Percentage;

        const discountValue = isDiscountTypePercentage
          ? (amount * res.coupon.discountAmount) / 100
          : res.coupon.discountAmount;

        setCouponState({
          ...couponState,
          isSuccessCoupon: true,
          couponErrorMessage: null,
          promoCode: couponCode,
          discountAmount: res.coupon.discountAmount,
          discountValue,
        });
        if (setObj && setDefCop) {
          setObj({});
          setDefCop(null);
        }
      } catch (error: any) {
        setCouponState({
          ...couponState,
          isSuccessCoupon: false,
          couponErrorMessage: error?.message || 'An error occurred',
          promoCode: '',
        });
        setIsInvalidCoupon(true);
        if (setObj && setDefCop) {
          setObj({});
          setDefCop(null);
        }
        throw error;
      }
    }
  };

  const onChangePromoCode = async (data: { coupon?: string }) => {
    if (!data.coupon || !setClientSecret) {
      return;
    }

    try {
      await onCouponSubmit(data);
    } catch (error) {
      console.log('ERROR');
      return;
    }

    if (isCertificate) {
      const { clientSecret, invoiceId: newInvoiceId } = await changeUseFirstCoupon({
        promocode: data.coupon,
        invoiceId,
        useFirstMonthDiscount,
      });
      setClientSecret(clientSecret);
      setInvoiceId(newInvoiceId);
    }

    if ((!isUpdate || isPurchase) && !isCertificate) {
      const { clientSecret, invoiceId: newInvoiceId } = await changePromoCode({ promocode: data.coupon, invoiceId });
      setClientSecret(clientSecret);
      setInvoiceId(newInvoiceId);
    }
  };

  return {
    couponState,
    isInvalidCoupon,
    removeCoupon,
    onCouponSubmit,
    setCouponState,
    setIsInvalidCoupon,
    isDefaultCoupon: Boolean(defaultCoupon),
    onChangePromoCode,
    isLoading,
    isCertificateLoading,
    isChangePromoCodeLoading,
  };
};
