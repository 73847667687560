import { Button } from 'UI';
import { ConfirmModal, FlashcardInitModal, PointsResultModal, ResetResultFlashcardsModal } from 'UI/Modals';
import MaximumNumberPointsModal from 'UI/Modals/MaximumNumberPointsModal';
import { CreateReportDtoResourceEnum, PaginationMetaDto } from 'api/generated';
import { SpamIcon } from 'assets/icons';
import { Loader, ReportModal } from 'components';
import { MAXIMUM_COURSE_POINTS } from 'constant/maximumPoints';
import { routes } from 'constant/routes';
import { useAsyncAction, useGetIsMobile, useNotifications, useQuery } from 'hooks';
import useClientSize from 'hooks/useClientSize';
import { FlashcardsLayout } from 'layouts/FlashcardsLayout';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { FlashcardsPackType } from 'types';

import { AllCards, Question, QuestionAndAnswer, QuestionsModal } from './components';
import WithoutCards from './components/WithoutCards';

enum FlashcardsModalEnum {
  INITIAL,
  QUESTION,
  CONFIRM,
  REPORT,
  ALREADY_LEARNED,
  COMPLETE,
  FINISH,
  MAXIMUM_POINTS,
  RESET_RESULT,
}

const MIN_COUNT_FOR_NEXT_FETCH = 5;

function Flashcards() {
  const query = useQuery();
  const packId = query.get('packId');
  const packType: FlashcardsPackType = packId ? 'cards' : 'questions';

  const { errorToast, successToast } = useNotifications();

  const dispatch = useAppDispatch();

  const currentIndex = useAppSelector(selectors.flashcards.selectCurrentFlashcardIndex);
  const currentFlashcardsPackId = useAppSelector(selectors.flashcards.selectCurrentFlashcardsPackId);
  const timeToNextAvailableCard = useAppSelector(selectors.flashcards.selectTimeToNextAvailableCard);
  const flashcardPackResult = useAppSelector(selectors.flashcards.selectPackFlashcardsResult);
  const flashcards = useAppSelector(selectors.flashcards.selectFlashcards);
  const packCompleteResult = useAppSelector(selectors.flashcards.selectCompletePackResult);
  const flashcardsMeta = useAppSelector(selectors.flashcards.selectFlashcardsMeta);
  const paymentLevel = useAppSelector(selectors.courses.selectCurrentPaymentLevel);
  const isShowStreakEnabled = useAppSelector(selectors.settings.showStreaks);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const isPauseCertificate = useAppSelector(selectors.courses.selectIsPauseCertificate);
  const [getFlashcardsAction, isFlashcardsLoading] = useAsyncAction(actions.flashcards.getFlashcards);
  const [getFlashcardsPackResultAction] = useAsyncAction(actions.flashcards.getFlashcardsPackResult);
  const [completePackAction, isCompleteLoading] = useAsyncAction(actions.flashcards.completeFlashcardsPack);
  const [getCourseStatisticAction, isStatisticLoading] = useAsyncAction(actions.performance.getCourseStatistic);

  const isCurrentIndex = typeof currentIndex === 'number';
  const isGoldSubscription = paymentLevel === 'gold';

  const [isStartedTest, setIsStartedTest] = useState(isCurrentIndex);
  const [currentModal, setCurrentModal] = useState<FlashcardsModalEnum | null>(null);
  const [isShowAnswer, setIsShowAnswer] = useState(false);

  const isShowInitModal = Boolean(!flashcardPackResult?.kinda && !flashcardPackResult?.no && !flashcardPackResult?.yes);
  const isAlreadyLearnedDeck = flashcardPackResult && flashcardPackResult?.yes === flashcardPackResult?.total;
  const isFlashcardPackResult = Boolean(flashcardPackResult);
  const currentPage = flashcardsMeta?.currentPage || 0;
  const totalPages = flashcardsMeta?.totalPages || 0;
  const nextPage = currentPage + 1;

  const showInitialModal = () => {
    if (isShowInitModal) {
      setCurrentModal(FlashcardsModalEnum.INITIAL);
    } else if (isAlreadyLearnedDeck) {
      setCurrentModal(FlashcardsModalEnum.ALREADY_LEARNED);
    } else {
      startTest();
    }
  };

  useEffect(() => {
    if (isFlashcardPackResult) {
      showInitialModal();
    }
  }, [isFlashcardPackResult]);

  const getFlashcardsPackResult = async (packId: string | null) => {
    try {
      const response = await getFlashcardsPackResultAction({ packId: packId || undefined, packType });
      return response;
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const getCourseStatistic = async () => {
    if (isPause || isPauseCertificate) {
      return;
    }
    try {
      const response = await getCourseStatisticAction();
      return response;
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const completePack = async () => {
    if (packType === 'cards') {
      try {
        const { total: packCompleteResult } = await completePackAction({ packId: packId || '' });
        const { total, yes } = (await getFlashcardsPackResult(packId)) || {};
        const isCompleteDeck = total === yes;
        const canFinishModalShow = typeof packCompleteResult === 'number' && packCompleteResult !== 0;
        if (isGoldSubscription && isShowStreakEnabled) {
          const { totalInPoints } = (await getCourseStatistic()) || {};
          openFinishModal(isCompleteDeck, canFinishModalShow, totalInPoints);
        } else {
          openFinishModal(isCompleteDeck, canFinishModalShow);
          setCurrentModal(null);
          navigate(routes.flashcardPacks);
        }
      } catch (error) {
        console.log(error);
        errorToast('Something went wrong');
      }
    } else if (packType === 'questions') {
      navigate(routes.flashcardPacks);
    }
  };

  const openFinishModal = (isCompleteDeck: boolean, canFinishModalShow: boolean, points?: number) => {
    if (!canFinishModalShow) {
      closeFinishModal();
    } else if (points === MAXIMUM_COURSE_POINTS) {
      setCurrentModal(FlashcardsModalEnum.MAXIMUM_POINTS);
    } else {
      setCurrentModal(isCompleteDeck ? FlashcardsModalEnum.COMPLETE : FlashcardsModalEnum.FINISH);
    }
  };

  useLayoutEffect(() => {
    getFlashcardsPackResult(packId);
  }, [currentIndex]);

  const getInitialFlashcards = async (packId: string | null, actionPayload?: PaginationMetaDto) => {
    try {
      const isCurrentId = packId && packId === currentFlashcardsPackId;
      if (!isCurrentId) {
        clearFlashcardsSlice();
        dispatch(actions.flashcards.setFlashcardsPackId(packId));

        await getFlashcardsAction({
          packId: packId || undefined,
          page: 1,
          onlyAvailable: true,
          packType,
          ...actionPayload,
        });
      }
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const clearFlashcardsSlice = () => dispatch(actions.flashcards.clearSlice());

  useLayoutEffect(() => {
    getInitialFlashcards(packId);
  }, [packId]);

  const isFetchAfter =
    (flashcards?.length || 0) - 1 - (currentIndex || 0) < MIN_COUNT_FOR_NEXT_FETCH && currentPage < totalPages;

  const getFlashcards = () => {
    if (isFetchAfter) {
      getFlashcardsAction({ packId: packId || '', packType, page: nextPage });
    }
  };

  useEffect(() => {
    getFlashcards();
  }, [currentIndex, flashcards]);

  const setCurrentFlashcardIndex = (index: number | null) => {
    dispatch(actions.flashcards.setCurrentFlashcardIndex(index));
  };

  useEffect(() => {
    localStorage.setItem('currentIndex', currentIndex?.toString() || '');
  }, [currentIndex]);

  useEffect(() => {
    const storedIndex = localStorage.getItem('currentIndex');
    if (storedIndex) {
      setCurrentFlashcardIndex(parseInt(storedIndex, 10));
    }
  }, []);

  const [resetResultAction, isResetLoading] = useAsyncAction(actions.flashcards.resetFlashcardsPackResult);

  const resetResult = async () => {
    try {
      await resetResultAction({ packId: packId || undefined, packType });
      dispatch(actions.flashcards.setCurrentFlashcardIndex(null));
      if (isGoldSubscription) {
        await getCourseStatistic();
      }
      setCurrentModal(null);
      successToast('All results have been reset');
      setIsStartedTest(false);
      startTest();
    } catch (error) {
      errorToast('An error has occurred, please try again');
    }
  };

  const { getIsBreakpoint } = useClientSize();
  const navigate = useNavigate();

  const { isMobile } = useGetIsMobile();

  const goToNextStep = () => {
    setTimeout(
      () => {
        setIsShowAnswer(false);
        setCurrentFlashcardIndex((currentIndex || 0) + 1);
      },
      isMobile ? 200 : 400,
    );
  };

  const startTest = () => {
    setCurrentModal(null);
    setIsStartedTest(true);
    setCurrentFlashcardIndex(0);
  };

  const closeTest = () => {
    setCurrentModal(null);
    navigate(routes.flashcardPacks);
  };

  const closeFinishModal = () => {
    setCurrentModal(null);
    navigate(routes.flashcardPacks);
  };

  const isDisableStartButton = !flashcards;

  const isShowAnswerComponent = flashcards && isStartedTest && isShowAnswer && isCurrentIndex;
  const isShowQuestionComponent = flashcards && isStartedTest && !isShowAnswer && isCurrentIndex;

  const currentFlashcard = flashcards?.[currentIndex || 0];

  const answerImage = currentFlashcard?.answerImageUrl
    ? {
        url: currentFlashcard?.answerImageUrl || '',
        alt: currentFlashcard?.answer || '',
      }
    : null;

  const questionImage = currentFlashcard?.questionImageUrl
    ? {
        url: currentFlashcard.questionImageUrl,
        alt: currentFlashcard.question,
      }
    : null;

  const { colors } = useTheme();
  const isWidthS = getIsBreakpoint('s');

  const isShowTimer = Boolean(!isAlreadyLearnedDeck && timeToNextAvailableCard);
  const isEarned = (packCompleteResult || 0) > 0;

  if (isFlashcardsLoading && !currentIndex) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }
  const isResetButtonLoading = isResetLoading || isFlashcardsLoading || isStatisticLoading;

  return (
    <FlashcardsLayout
      showConfirmModal={() => setCurrentModal(FlashcardsModalEnum.CONFIRM)}
      showModal={() => setCurrentModal(FlashcardsModalEnum.QUESTION)}
      isStartedTest={isStartedTest}
      onInfoIconClick={() => setCurrentModal(FlashcardsModalEnum.INITIAL)}
      onResetClick={() => setCurrentModal(FlashcardsModalEnum.RESET_RESULT)}
      onReportClick={() => setCurrentModal(FlashcardsModalEnum.REPORT)}>
      {isShowAnswerComponent && !isShowTimer && (
        <QuestionAndAnswerContainer>
          <QuestionAndAnswer
            showNextQuestion={goToNextStep}
            questionText={currentFlashcard?.question}
            answerText={currentFlashcard?.answer}
            answerImage={answerImage}
            questionImage={questionImage}
            nextIntervals={currentFlashcard?.nextIntervals}
            id={currentFlashcard?.id || ''}
            isLoading={isCompleteLoading}
            completePack={completePack}
            packType={packType}
          />
        </QuestionAndAnswerContainer>
      )}
      {isShowQuestionComponent && !isShowTimer && (
        <Question image={questionImage} text={currentFlashcard?.question || ''} callback={setIsShowAnswer} />
      )}
      <FlashcardInitModal
        isFlashcardsLoading={isFlashcardsLoading}
        isDisableStartButton={isDisableStartButton}
        isOpen={currentModal === FlashcardsModalEnum.INITIAL}
        startTest={startTest}
        onClose={closeTest}
        isInitModal={isShowInitModal}
      />
      <QuestionsModal isOpen={currentModal === FlashcardsModalEnum.QUESTION} onClose={() => setCurrentModal(null)}>
        <AllCards packType={packType} />
      </QuestionsModal>
      <ConfirmModal
        isOpen={currentModal === FlashcardsModalEnum.CONFIRM}
        showCloseButton={!isWidthS}
        onClose={() => setCurrentModal(null)}
        onLeftButtonClick={currentIndex ? completePack : () => navigate(routes.flashcardPacks)}
        isLeftButtonLoading={isCompleteLoading}
        title="Do you want to finish?"
      />
      {isShowTimer && <WithoutCards packType={packType} addedTime={timeToNextAvailableCard || 0} />}
      {isStartedTest && (
        <ReportButton
          iconComponent={<SpamIcon width={24} height={24} color={colors.system.red} />}
          variant="secondary"
          size="small"
          onClick={() => setCurrentModal(FlashcardsModalEnum.REPORT)}>
          Report a problem
        </ReportButton>
      )}
      <ConfirmModal
        isOpen={currentModal === FlashcardsModalEnum.ALREADY_LEARNED}
        showCloseButton={false}
        onClose={() => setCurrentModal(null)}
        title="You have learned all the cards in this deck"
        text="Do you want to reset the results and start over?"
        isLeftButtonLoading={isResetButtonLoading}
        onLeftButtonClick={resetResult}
        onRightButtonClick={() => navigate(routes.flashcardPacks)}
        shouldCloseOnOverlayClick={false}
      />
      <PointsResultModal
        isOpen={currentModal === FlashcardsModalEnum.FINISH}
        onClose={closeFinishModal}
        title={isEarned ? 'Congratulation!' : 'Oops...'}
        subtitle={isEarned ? 'You have earned' : ' You have lost'}
        isEarned={isEarned}
        onLinkClick={() => navigate(routes.performance)}
        points={Math.abs(packCompleteResult || 0)}
      />
      <PointsResultModal
        isOpen={currentModal === FlashcardsModalEnum.COMPLETE}
        onClose={closeFinishModal}
        title={'Congratulations, you have learned\n all the cards in this deck!'}
        isEarned={true}
        points={packCompleteResult || 0}
        onLinkClick={() => navigate(routes.performance)}
      />
      <MaximumNumberPointsModal
        isOpen={currentModal === FlashcardsModalEnum.MAXIMUM_POINTS}
        onClose={closeFinishModal}
        points={Math.abs(packCompleteResult || 0)}
      />
      <ResetResultFlashcardsModal
        isOpen={currentModal === FlashcardsModalEnum.RESET_RESULT}
        onClose={() => setCurrentModal(null)}
        onYesButtonClick={resetResult}
        isLoading={isResetButtonLoading}
      />
      <ReportModal
        resource={packId ? CreateReportDtoResourceEnum.Flashcard : CreateReportDtoResourceEnum.TestQuestion}
        resourceId={currentFlashcard?.id || ''}
        isOpen={currentModal === FlashcardsModalEnum.REPORT}
        onClose={() => setCurrentModal(null)}
      />
    </FlashcardsLayout>
  );
}

export default Flashcards;

const QuestionAndAnswerContainer = styled.div`
  padding: 0 16px;
  ${respondToWidth.ls`
    padding:0;
    height: 100%;
    display: grid;
    place-items: center;
  `}
`;

const ReportButton = styled(Button)`
  display: none;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.sm`
    display: flex;
  `}
`;

const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
`;
