import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaymentDtoLevelEnum } from 'api/generated';

import {
  confirmRestorePasswordThunk,
  getCustomerByEmailThunk,
  restorePasswordThunk,
  signInThunk,
  signUpByEmailThunk,
  signUpPromoThunk,
} from './actions';

interface AuthSlice {
  accessToken: null | string;
  email: string;
  customerId: string;
  stripeCustomerId: string;
  isExistingCustomer: boolean;
  level: PaymentDtoLevelEnum | '';
}

const initialState: AuthSlice = {
  accessToken: '',
  email: '',
  customerId: '',
  stripeCustomerId: '',
  isExistingCustomer: false,
  level: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state) => ({ ...initialState, email: state.email }),
    setIsAuthenticated: (state, action: PayloadAction<string>) => ({
      ...state,
      accessToken: action.payload,
    }),
    setEmail: (state, action: PayloadAction<string>) => ({
      ...state,
      email: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInThunk.fulfilled, (state, { payload }) => {
        const { token } = payload;
        if (token) {
          state.accessToken = token;
        }
      })
      .addCase(restorePasswordThunk.fulfilled, (state, { payload }) => {
        const email = payload.customer?.email;
        if (email) {
          state.email = email;
        }
      })
      .addCase(signUpByEmailThunk.fulfilled, (state, { payload }) => {
        state.email = payload.customer.email;
        state.accessToken = payload.token;
      })
      .addCase(signUpPromoThunk.fulfilled, (state, { payload }) => {
        state.email = payload.customer.email;
        state.accessToken = payload.token;
      })
      .addCase(getCustomerByEmailThunk.fulfilled, (state, { payload }) => {
        state.customerId = payload.customerId;
        state.stripeCustomerId = payload.stripeCustomerId;
        state.isExistingCustomer = payload.isExistingCustomer;
      });
  },
});

export const actions = {
  ...authSlice.actions,
  signIn: signInThunk,
  restorePassword: restorePasswordThunk,
  confirmRestorePassword: confirmRestorePasswordThunk,
  signUpByEmail: signUpByEmailThunk,
  signUpPromo: signUpPromoThunk,
};

export const { reducer } = authSlice;
