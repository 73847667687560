import { Checkbox, StyledText, Tooltip } from 'UI';
import { GetQuestionChaptersResponseItem, GetQuestionDomainsResponseItem, PaymentDtoLevelEnum } from 'api/generated';
import { LockedIcon, UnlockIcon } from 'assets/icons';
import { useClientSize, useFormWatch, useGetIsMobile, useOnClickOutside } from 'hooks';
import { TestFormType } from 'pages/TestList/TestList';
import React, { FC, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { ModeType } from 'types';
import { getCircumcisionCountForTestSubject, stringCircumcision } from 'utils/textHelpers';

enum Mode {
  all = 'all',
  unused = 'unused',
  incorrect = 'incorrect',
  marked = 'marked',
}

const modeAdapter: Record<ModeType, Mode> = {
  ALL: Mode.all,
  UNUSED: Mode.unused,
  INCORRECT: Mode.incorrect,
  MARKED: Mode.marked,
};

type SubjectFieldType = 'chaptersQuestions' | 'domainsQuestions';

type SubjectProps = {
  isAboveTooltip: boolean;
  field: SubjectFieldType;
  subject: GetQuestionChaptersResponseItem | GetQuestionDomainsResponseItem;
  isTutor: boolean;
  isLeftTooltip?: boolean;
  onLockClick?: () => void;
};

const Subject: FC<SubjectProps> = ({ subject, field, isAboveTooltip, isTutor, onLockClick }) => {
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const { isMobile } = useGetIsMobile();
  const { getBreakpoint } = useClientSize();
  const rootRef = useRef<HTMLDivElement>(null);
  const paymentLevel = useAppSelector(selectors.courses.selectCurrentPaymentLevel);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const isPauseCertificate = useAppSelector(selectors.courses.selectIsPauseCertificate);

  const isGoldOrSilver = paymentLevel !== PaymentDtoLevelEnum.None && paymentLevel !== PaymentDtoLevelEnum.Bronze;
  const isEffectiveTrial =
    isPause || (!isGoldOrSilver && paymentLevel === PaymentDtoLevelEnum.None) || isPauseCertificate;
  const paymentLevelLockText = isEffectiveTrial
    ? 'You have a trial version, to get a full access, please buy a MVP subscription'
    : 'You have a rookie subscription, to get a full access, please buy a MVP subscription';

  const { register, watch } = useFormContext<TestFormType>();
  const watchField = useFormWatch(watch);
  const chapterIds = watchField(field);
  const customChecked = (value: string) => Boolean(chapterIds?.find((id) => id === value));
  const modes = watchField('mode');
  const mode = modeAdapter[modes[0]];
  const total = modes?.length === 1 ? subject[mode].total : subject.unused.total + subject.incorrect.total;
  const free = modes?.length === 1 ? subject[mode].free : subject.unused.free + subject.incorrect.free;

  /**
   * This function calculates a count of the questions inside domain or chapter
   * and constructs a value string with a slash symbol or without it: (10) or (5/10)
   * It depends on the Payment level and current mode
   * @param subject
   */
  const calculateQuestionCount = () => {
    if (isGoldOrSilver) {
      return total;
    } else {
      if (total !== free) {
        return free !== 0 ? `${free}/${total}` : total;
      }
      return free;
    }
  };

  const breakPoint = getBreakpoint();
  const questionsCount = calculateQuestionCount();

  /**
   * This const is needed to calculate a length of the domain or chapter title
   * This value depends on the questions count symbol length also: String(questionsCount)?.toString()?.length
   * "35" - is a hardcode value for the mobile breakpoint
   */
  const stringCircumcisionCount = isMobile
    ? 35 - String(questionsCount)?.toString()?.length
    : getCircumcisionCountForTestSubject(breakPoint) - (4 + String(questionsCount)?.toString()?.length - 1);
  const text = stringCircumcision(subject.title, stringCircumcisionCount);

  const hasNoQuestions = isEffectiveTrial ? free === 0 : total === 0;
  const isLockNeeded = isEffectiveTrial ? free === 0 : false;

  useOnClickOutside(rootRef, () => setIsShowTooltip(false));

  const toggleTooltipOnMobile = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsShowTooltip((prev: boolean) => !prev);
  };

  const handleLockClick = () => {
    if (isLockNeeded) {
      onLockClick?.();
    } else {
      return;
    }
  };

  return (
    <>
      {isMobile ? (
        <Root ref={rootRef}>
          <Inner onClick={isLockNeeded ? handleLockClick : undefined}>
            <StyledCheckbox
              key={subject.id}
              label={text}
              value={subject.id}
              disabled={(isTutor ? hasNoQuestions : true) || isLockNeeded}
              checked={isTutor ? customChecked(subject.id) : true}
              onClick={toggleTooltipOnMobile}
              {...register(field)}
            />
            <Counter $disabled={hasNoQuestions}>({questionsCount})</Counter>
            {isLockNeeded && (hasNoQuestions ? <LockedIcon /> : <UnlockIcon />)}
            {isShowTooltip && (
              <FullTitle font="footnote_semibold_12" onClick={toggleTooltipOnMobile}>
                {isLockNeeded ? paymentLevelLockText : subject.title}
              </FullTitle>
            )}
          </Inner>
        </Root>
      ) : (
        <Root ref={rootRef}>
          <StyledTooltip
            text={isLockNeeded ? paymentLevelLockText : subject.title}
            title={isLockNeeded ? paymentLevelLockText : text}
            windowCSS={TooltipCss}
            isAbove={isAboveTooltip}
            shouldPreventDefault={false}>
            <Inner onClick={isLockNeeded ? handleLockClick : undefined}>
              <StyledCheckbox
                key={subject.id}
                label={text}
                value={subject.id}
                disabled={(isTutor ? hasNoQuestions : true) || isLockNeeded}
                checked={isTutor ? customChecked(subject.id) : true}
                {...register(field)}
              />

              <Counter $disabled={hasNoQuestions}>({questionsCount})</Counter>
              {isLockNeeded && <LockedIcon />}
              {isLockNeeded || (hasNoQuestions ? <LockedIcon /> : !isGoldOrSilver && <UnlockIcon />)}
            </Inner>
          </StyledTooltip>
        </Root>
      )}
    </>
  );
};

export default Subject;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ theme: { typography } }) => css`
    cursor: pointer;
    width: 100%;
    text-align: left;
    ${typography.body_basic_semibold_14};
  `};
`;

const TooltipCss = css`
  left: 0;
  width: 100%;
  z-index: 4;
`;

const FullTitle = styled(StyledText)`
  ${({ theme: { colors } }) => css`
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -4px;
    padding: 12px 16px;
    text-align: left;
    border-radius: 12px;
    z-index: 4;
    transform: translateY(100%);

    color: ${colors.neutrals[11]};
    background-color: ${colors.neutrals[1]};
  `};
`;

const Counter = styled.span<{ $disabled?: boolean }>`
  ${({ theme: { colors, typography }, $disabled }) => css`
    color: ${$disabled ? colors.neutrals[4] : colors.neutrals[1]};
    ${typography.body_basic_semibold_14};
    margin-left: auto;
    margin-right: 4px;
  `}
`;

// const lockContainer = css`
//   position: relative;
//   display: flex;
//   align-self: flex-end;
//   justify-content: flex-end;
// `;
