import { StyledText, TextButton } from 'UI';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import FlashcardsStatistics from './components/FlashcardsStatistics';
import TestsStatistics from './components/TestsStatistics';

export type AnswerTypes = {
  amount: number;
  percent: number;
};

const Performance = () => {
  const navigate = useNavigate();
  const { errorToast } = useNotifications();

  const [getStatisticByTestsAction] = useAsyncAction(actions.performance.getStatisticByTests);
  const [getStatisticByFlashcardsAction] = useAsyncAction(actions.performance.getStatisticByFlashcards);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const isPauseCertificate = useAppSelector(selectors.courses.selectIsPauseCertificate);

  const getStatisticByTests = async () => {
    if (isPause) {
      return;
    }
    try {
      await getStatisticByTestsAction();
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const getStatisticByFlashcards = async () => {
    if (isPause || isPauseCertificate) {
      return;
    }
    try {
      await getStatisticByFlashcardsAction();
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    if (!isPause) {
      getStatisticByTests();
      getStatisticByFlashcards();
    }
  }, []);

  return (
    <Container>
      <Title>Performance</Title>
      <StyledTestStatistic />
      <FlashcardsStatistics />
      <Wrapper>
        <Cover>
          <Circle />
          <Label>Unused</Label>
        </Cover>
        <TextButton text="More Information" onClick={() => navigate(routes.performance)} />
      </Wrapper>
    </Container>
  );
};

export default Performance;

const Container = styled.div`
  width: 100%;
  padding: 29px 32px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 12px;

  ${respondToWidth.lg`
    padding-inline: 109px;
  `}

  ${respondToWidth.ls`
    padding-inline: 105px;
  `}

  ${respondToWidth.sm`
    padding-inline: 70px;
  `}

  ${respondToWidth.s`
    padding: 32px 16px;
  `}
`;

const Title = styled(StyledText)`
  text-align: center;
  margin-bottom: 20px;
  ${({ theme: { typography } }) => typography.title_4_bold_24};

  ${({ theme: { typography } }) => respondToWidth.s`
    ${typography.headline_semibold_18};
  `};
`;

const StyledTestStatistic = styled(TestsStatistics)`
  margin-bottom: 35px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
`;

const Cover = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[4]};
`;

const Label = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[5]};
  ${({ theme: { typography } }) => typography.subhead_medium_13};
`;
