import { Button, ConfirmModal, ResetResultCourseModal, SecondButton } from 'UI';
import { SecondButtonVariant } from 'UI/SecondButton/SecondButton';
import api from 'api';
import { ManualTypeEnum } from 'api/generated';
import { UpgradeSubscriptionModal } from 'components';
import { routes } from 'constant/routes';
import { useAsyncAction, useClientSize, useNotifications, useToggle } from 'hooks';
import MainLayout from 'layouts/MainLayout';
import { Courses } from 'pages';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { AccountInfoCard, SettingButtonsList } from './components';

const Profile = () => {
  const { isOpen: isSignOutModalOpen, close: closeSignOutModalOpen, open: openSignOutModalOpen } = useToggle();

  const {
    isOpen: isConfirmResetCourseResultModalOpen,
    close: closeConfirmResetCourseResultModal,
    open: openConfirmResetCourseResultModalOpen,
  } = useToggle();

  const { isOpen: isOpenExamModal, close: closeExamModal, open: openExamModal } = useToggle();

  const [resetCourseResultsAction, isResetCourseResultsLoading] = useAsyncAction(actions.courses.resetCourseResults);
  const [getMyCourses, isCoursesLoading] = useAsyncAction(actions.courses.getMyCourses);
  const [getCourseStatisticAction] = useAsyncAction(actions.performance.getCourseStatistic);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const isPauseCertificate = useAppSelector(selectors.courses.selectIsPauseCertificate);
  const user = useAppSelector(selectors.user.selectUser);
  const direction = useAppSelector(selectors.direction.selectDirection);
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);

  const { colors } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { errorToast, successToast } = useNotifications();
  const { boot, show, shutdown } = useIntercom();

  const { getIsBreakpoint } = useClientSize();
  const isWidthLs = getIsBreakpoint('ls');

  const handleOpenBillingPage = () => {
    navigate(routes.billing);
  };

  const openSupport = useCallback(async () => {
    try {
      const { data: userHash } = await api.Customer.customersControllerCreateUserIntercomHash();

      boot({
        // eslint-disable-next-line camelcase
        customAttributes: { custom_attribute_key: 'hi there' },
        company: { companyId: 'trainer', name: 'trainer' },
        actionColor: colors.primary[1],
        backgroundColor: colors.primary[1],
        name: user?.name,
        email: user?.email,
        userId: user?.id,
        userHash,
        utmSource: direction?.name,
        hideDefaultLauncher: true,
      });
      show();
    } catch {
      errorToast('Something went wrong');
    }
  }, [colors, user, direction]);

  const signOut = () => {
    shutdown();
    dispatch(actions.auth.signOut());

    navigate(routes.signIn);
  };

  const resetCourseResults = async () => {
    try {
      await resetCourseResultsAction();
      if (!isPause && !isPauseCertificate) {
        await getCourseStatisticAction();
      }

      closeConfirmResetCourseResultModal();
      successToast('Your course results successfully reset');
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    getMyCourses();
  }, []);

  return (
    <StyledMainLayout>
      <Root>
        <Title>Profile</Title>

        <Container>
          <InfoContainer>
            <InfoWrapper>
              <AccountInfoCard />

              <StyledButton size="small" onClick={openExamModal}>
                Sign up for the exam
              </StyledButton>

              {isWidthLs && <Courses isLoading={isCoursesLoading} />}

              <ButtonsContainer>
                <SettingButtonsList
                  openConfirmResetCourseResultModalOpen={openConfirmResetCourseResultModalOpen}
                  isResetCourseResultsLoading={isResetCourseResultsLoading}
                />

                <SecondButton variant={SecondButtonVariant.SECONDARY} text="Support" onClick={openSupport} />
                <SecondButton variant={SecondButtonVariant.SECONDARY} text="Billing" onClick={handleOpenBillingPage} />
              </ButtonsContainer>
            </InfoWrapper>

            <ButtonsContainer>
              <SecondButton onClick={openSignOutModalOpen} text="Sign out" />
            </ButtonsContainer>
          </InfoContainer>

          {!isWidthLs && <Courses isLoading={isCoursesLoading} />}
          <ConfirmModal
            title="Are you sure you want to sign out?"
            isOpen={isSignOutModalOpen}
            onClose={closeSignOutModalOpen}
            onLeftButtonClick={signOut}
            onRightButtonClick={closeSignOutModalOpen}
          />

          <ResetResultCourseModal
            courseName={activeCourse?.name || ''}
            isOpen={isConfirmResetCourseResultModalOpen}
            onClose={closeConfirmResetCourseResultModal}
            onLeftButtonClick={resetCourseResults}
            onRightButtonClick={closeConfirmResetCourseResultModal}
            isLoading={isResetCourseResultsLoading}
          />

          <UpgradeSubscriptionModal
            isOpen={isOpenExamModal}
            onClose={closeExamModal}
            manualType={ManualTypeEnum.CertificateSignup}
            isBonusModal={true}
          />
        </Container>
      </Root>
    </StyledMainLayout>
  );
};

export default Profile;

const StyledMainLayout = styled(MainLayout)`
  ${respondToWidth.sm`
    width: 100%;
  `}
`;

const Root = styled.div`
  ${({ theme: { colors } }) => css`
    height: 100%;

    padding: 28px 40px 40px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    ${respondToWidth.sm`
      display: block;
      padding: 16px;
      padding-bottom: 125px;
      overflow: hidden;
      background-color: ${colors.neutrals[11]};
    `}
  `}
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding: 10px 0px;
`;

const Title = styled.h1`
  ${({ theme: { typography, colors } }) => css`
    ${typography.title_2_bold_32}
    color: ${colors.neutrals[1]};
  `}

  display: flex;
  margin-bottom: 20px;
  width: 100%;
`;

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    display: grid;
    grid-template-columns: 288px 1fr;
    grid-gap: 24px;

    ${respondToWidth.ls`
    grid-template-columns: 1fr;
  `}

    ${respondToWidth.sm`
      display: block;
      padding: 16px;
      padding-bottom: 125px;
      overflow: hidden;
      background-color: ${colors.neutrals[11]};

    `}
  `}
`;

const InfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  gap: 24px;

  ${respondToWidth.sm`
      gap: 32px;
  `}
`;

const InfoWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;

    gap: 24px;

    ${respondToWidth.sm`
      gap: 32px;
      padding-bottom: 32px;

      border-bottom: 1px solid ${colors.neutrals[9]};
    `}
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  ${respondToWidth.sm`
      gap: 16px;
    `}
`;
