import { Stripe, StripeElements } from '@stripe/stripe-js';
import api from 'api';
import { PaymentDtoLevelEnum, StripePaymentSource, SubscribeDtoPeriodEnum, SubscribeDtoTypeEnum } from 'api/generated';
import { useAsyncAction, useStripeChangePlan, useStripeSubscribeOrPurchase } from 'hooks';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import { PaymentOptions } from 'store/ducks/courses/types';

interface Props {
  stripe: Stripe | null;
  elements: StripeElements | null;
  courseId?: string;
  promocode: string;
  period?: SubscribeDtoPeriodEnum;
  level?: PaymentDtoLevelEnum;
  source?: StripePaymentSource;
  isTrial?: boolean;
  useFirstMonthDiscount: boolean;
  isUpdate?: boolean;
  isCertificate: boolean | undefined;
  isPurchase?: boolean;
  courseName?: string;
  amount?: number;
  subscribeType: SubscribeDtoTypeEnum;
  setInvoiceId: Dispatch<SetStateAction<string>>;
  clientSecret: string | undefined;
  setClientSecret: Dispatch<SetStateAction<string | undefined>>;
  setTransactionId: Dispatch<SetStateAction<string | undefined>>;
}

type PurchaseParams = {
  courseId: string;
  promocode?: string;
  level: string;
  source: string;
};

type SubscribeParams = PurchaseParams & {
  period: number;
};

type InstallmentParams = PurchaseParams & {
  useFirstMonthDiscount: boolean;
};

type Response = {
  clientSecret: string;
  invoiceId: string;
};

export const useStripeDefault = ({
  stripe,
  elements,
  courseId,
  promocode,
  period,
  level,
  source,
  isCertificate,
  isTrial,
  isUpdate,
  isPurchase,
  courseName,
  useFirstMonthDiscount,
  amount,
  subscribeType,
  clientSecret,
  setInvoiceId,
  setClientSecret,
  setTransactionId,
}: Props) => {
  // @ts-ignore
  const [subscribeCourseAction] = useAsyncAction(actions.payments.subscribeCourse);
  // @ts-ignore
  const [purchaseCourseAction] = useAsyncAction(actions.payments.purchaseCourse);
  // @ts-ignore
  const [purchaseCourseInstallment] = useAsyncAction(actions.payments.purchaseCourseInstallment);

  const paymentOption = useAppSelector(selectors.courses.selectPaymentOption);

  const isOneTime = paymentOption === PaymentOptions.ONETIME;

  const isNonPurchaseUpdate = isUpdate && !isPurchase;

  const params = {
    elements,
    stripe,
    clientSecret,
    courseId,
    source,
    period,
  };

  const {
    handleSubmit: handleSubmitSubscribeOrPurchase,
    isLoading: isLoadingSubscribeOrPurchase,
    errorMessage: errorMessageSubscribeOrPurchase,
    isSuccess: isSuccessSubscribeOrPurchase,
  } = useStripeSubscribeOrPurchase({
    ...params,
    promocode,
    level,
    isTrial,
    isPurchase,
    courseName,
    amount,
    setTransactionId,
  });

  const {
    handleSubmit: handleSubmitChangePlan,
    isLoading: isLoadingChangePlan,
    errorMessage: errorMessageChangePlan,
    isSuccess: isSuccessChangePlan,
  } = useStripeChangePlan({
    ...params,
    level: subscribeType,
    setTransactionId,
  });

  const onGetClientSecretSubscribeOrPurchase = async () => {
    if (!courseId || !level || !source || !period) return;

    const params = {
      courseId,
      promocode,
      level,
      source,
    };

    const onResponse = async (
      action: (params: any) => Promise<Response>,
      actionParams: SubscribeParams | InstallmentParams | PurchaseParams,
    ) => {
      const { clientSecret, invoiceId } = await action(actionParams);
      setClientSecret(clientSecret);
      setInvoiceId(invoiceId);
    };

    if ((isPurchase && !isCertificate) || (isPurchase && isCertificate && isOneTime)) {
      await onResponse(purchaseCourseAction, params);
    }

    if (isPurchase && isCertificate && !isOneTime) {
      const installmentParams = { ...params, useFirstMonthDiscount };
      console.log(installmentParams);
      const res = await onResponse(purchaseCourseInstallment, installmentParams);
      console.log('res', res);
    }

    if (!isPurchase) {
      const subscribeParams = { ...params, period };
      await onResponse(subscribeCourseAction, subscribeParams);
    }
  };

  const onGetClientSecretChangePlan = async () => {
    const { data } = await api.Stripe.stripeControllerCustomerSetupIntentSecret();

    setClientSecret(data.clientSecret || '');
  };

  useEffect(() => {
    if (isUpdate && !isPurchase) {
      onGetClientSecretChangePlan();
    }

    if (stripe && (!isUpdate || isPurchase)) {
      onGetClientSecretSubscribeOrPurchase();
    }
  }, [stripe]);

  const isDisabled = !stripe || !clientSecret;

  return {
    clientSecretStripe: clientSecret,
    handleSubmitStripe: isNonPurchaseUpdate ? handleSubmitChangePlan : handleSubmitSubscribeOrPurchase,
    errorMessageStripe: isNonPurchaseUpdate ? errorMessageChangePlan : errorMessageSubscribeOrPurchase,
    isLoadingStripe: isNonPurchaseUpdate ? isLoadingChangePlan : isLoadingSubscribeOrPurchase,
    isSuccessStripe: isNonPurchaseUpdate ? isSuccessChangePlan : isSuccessSubscribeOrPurchase,
    isDisabledStripe: isDisabled,
  };
};
