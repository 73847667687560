import Button from 'UI/Button';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import StyledText from 'UI/StyledText';
import { CourseDtoTypeEnum, PaymentDto, SubscribeDtoTypeEnum } from 'api/generated';
import { CheckMark, Cross } from 'assets/icons';
import { ActivePlan, Plan } from 'components/Modals/ChooseProductModal/components/Products/types';
import { levelMapping } from 'constant';
import Lottie from 'lottie-react';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { actions, useAppDispatch } from 'store';
import { CourseOptionsForPayment } from 'store/ducks/courses/types';
import styled from 'styled-components';

import { BonusSection } from './components/BonusSection/BonusSection';
import { PriceSection } from './components/PriceSection/PriceSection';
import { ResponsiveCapability } from './components/ResponsiveCapability/ResponsiveCapability';
import { ResponsiveTabs } from './components/ResponsiveTabs/ResponsiveTabs';

export type CapabilityType = {
  include: boolean;
  title?: string;
};

type PlanItemProps = {
  plan: Plan;
  hasSubscription?: boolean;
  isShowButton?: boolean;
  isLowest?: boolean;
  isCurrentSubscription?: boolean;
  buttonTitle?: string;
  className?: string;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  planType: ActivePlan | undefined;
  animationData: any;
  counts: any;
  nextPayment?: PaymentDto;
  termsCount: number;
  currentPayment: PaymentDto | undefined;
  isSubscriptionUpdating: boolean;
  isMaxHeight: boolean;
  countPlans: number;
  activePeriod: string;
  setActivePeriod: Dispatch<SetStateAction<string>>;
  setIsShowBonusModal: Dispatch<SetStateAction<boolean>>;
  indexPlan: number;
  isResponsive: boolean;
  isShow: boolean;
  hasSubscriptionDowngrade?: boolean;
  courseId: string;
  setPlanType: Dispatch<SetStateAction<ActivePlan | undefined>>;
  cancelSubscriptionDowngrade: (courseId: string, isCancelTrial?: boolean) => Promise<void>;
  toggleSubscription: (options: CourseOptionsForPayment, isCurrent: boolean) => void;
  // TODO change to a necessary type after the Dto will be updated
  mostEffectiveLabel?: {
    phrase?: string;
    color?: string;
  };
};

const SubscriptionItem: FC<PlanItemProps> = ({
  nextPayment,
  hasSubscriptionDowngrade = false,
  isCurrentSubscription = false,
  hasSubscription = false,
  isLowest = false,
  isShowButton = true,
  isResponsive,
  counts,
  animationData,
  activePeriod,
  setActivePeriod,
  setIsShowBonusModal,
  buttonTitle,
  className,
  planType,
  countPlans,
  setPlanType,
  currentPayment,
  isSubscriptionUpdating,
  setActiveIndex,
  isMaxHeight,
  termsCount,
  isShow,
  courseId,
  plan,
  cancelSubscriptionDowngrade,
  toggleSubscription,
}) => {
  const [options, setOptions] = useState<any>();
  const [isSelect, setIsSelect] = useState(false);
  const [term, setTerm] = useState<any>();
  const [isCurrentSubs, setIsCurrentSubs] = useState(isCurrentSubscription);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const title = plan ? levelMapping[plan.type] : levelMapping.bronze;
  const currentPrice = plan?.priceMonthly?.amount || 0;
  const currentPriceAnnual = plan?.priceAnnual?.amount;
  // @ts-ignore
  const currentPriceLifetime = plan.purchasePrice?.amount;
  const capabilities = plan?.capabilities;
  const capabilitiesResponsive = plan.capabilitiesResponsive;

  const isLifeTime = term === TermEnum.LIFE_TIME;
  const subscribeType = plan.type;
  const isGoldSubscribe = subscribeType === 'gold';
  const isCurrentTermPeriod = currentPayment?.period === term;

  const disabled =
    (isCurrentTermPeriod && currentPayment?.level === plan.type && Boolean(!currentPayment?.activeTo)) ||
    isSubscriptionUpdating;

  const isDowngradeForCurrentTerm = nextPayment && nextPayment.period === term;
  const isNextPaymentForCurrentPlan = nextPayment && nextPayment.level === plan.type;
  const isCurrentSubscribe = currentPayment?.level === subscribeType;

  const buttonText = isCurrentSubs ? 'Unsubscribe' : isLifeTime ? 'Purchase' : buttonTitle || 'Subscribe';
  const buttonDowngradeText = isDowngradeForCurrentTerm ? 'Cancel downgrade' : buttonText;

  const currentButtonText = hasSubscriptionDowngrade ? buttonDowngradeText : buttonText;

  const shouldDisableButton = disabled || (hasSubscriptionDowngrade && !isDowngradeForCurrentTerm);

  const isButtonDisabled = isCurrentSubs || shouldDisableButton;
  const isPrimary = !isButtonDisabled;

  const isDisabled =
    disabled ||
    (isNextPaymentForCurrentPlan && !isDowngradeForCurrentTerm) ||
    (hasSubscriptionDowngrade && !isNextPaymentForCurrentPlan);

  const handleCancelSubscriptionDowngrade = async () => {
    setIsLoading(true);
    await cancelSubscriptionDowngrade?.(courseId);
    setIsLoading(false);
  };

  const onChoosePlan = (plan: Plan, term: TermEnum, subscribeType: SubscribeDtoTypeEnum) => {
    const options = {
      term,
      type: CourseDtoTypeEnum.Default,
      courseId,
      subscribeType,
      amount: plan?.amount,
      oldAmount: plan?.oldAmount,
      isUpdate: hasSubscription,
      isDowngrade: isLowest,
      couponCode: plan?.defaultCoupon?.promoCode,
      discount: plan?.cancellationPromotionTerms?.discount,
      discountType: plan?.cancellationPromotionTerms?.discountType,
      duration: plan?.cancellationPromotionTerms?.duration,
      durationInMonth: plan?.cancellationPromotionTerms?.durationInMonths,
    };
    setOptions(options);
    setIsCurrentSubs(currentPayment?.period === term && isCurrentSubscription);
    setTerm(term);
    setPlanType({ planType: subscribeType, term: term });
    setIsSelect(false);
  };

  dispatch(actions.courses.setDisabledStatus(isDisabled));

  useEffect(() => {
    const isYearly =
      (nextPayment && nextPayment.period === TermEnum.YEARLY) ||
      (isCurrentSubscribe && currentPayment.period === TermEnum.YEARLY);

    const isMonthly =
      (nextPayment && nextPayment.period === TermEnum.MONTHLY) ||
      (isCurrentSubscribe && currentPayment.period === TermEnum.MONTHLY);

    const isLifeTime =
      (nextPayment && nextPayment.type === 'Purchase') || (isCurrentSubscribe && currentPayment.type === 'Purchase');

    if (isYearly) {
      onChoosePlan(
        plan.priceAnnual,
        TermEnum.YEARLY,
        nextPayment ? (nextPayment.level as SubscribeDtoTypeEnum) : subscribeType,
      );
    }

    if (isMonthly) {
      onChoosePlan(
        plan.priceMonthly,
        TermEnum.MONTHLY,
        nextPayment ? (nextPayment.level as SubscribeDtoTypeEnum) : subscribeType,
      );
    }

    if (isLifeTime) {
      onChoosePlan(
        // @ts-ignore
        plan.purchasePrice,
        TermEnum.LIFE_TIME,
        nextPayment ? (nextPayment.level as SubscribeDtoTypeEnum) : subscribeType,
      );
    }

    if (!nextPayment && !isCurrentSubscribe) {
      onChoosePlan(plan.priceMonthly, TermEnum.MONTHLY, subscribeType);
      setIsSelect(true);
    }
  }, [isCurrentSubscribe, currentPayment?.period, plan.priceAnnual, plan.priceMonthly, subscribeType]);

  return (
    <div style={{ display: 'flex' }}>
      <Container className={className} type={plan.type} isShow={isShow && isResponsive} isResponsive={isResponsive}>
        {isGoldSubscribe && !isResponsive && <MostEffectiveTable>99% PASS RATE</MostEffectiveTable>}
        {!isGoldSubscribe && <div style={{ height: '35px' }}></div>}
        <PlanInformation>
          <AnimatedContainer>
            <Lottie animationData={animationData} />
          </AnimatedContainer>
          <TitleWithLabel>
            <Title>{title}</Title>
          </TitleWithLabel>
          {isGoldSubscribe && <Table isResponsive={isResponsive}>Flash Sale: Save 30%</Table>}

          {!isGoldSubscribe && !isResponsive && <div style={{ height: isMaxHeight ? '81px' : '25px' }}></div>}

          <CommonPriceContainer isResponsive={isResponsive} isMaxHeight={isMaxHeight}>
            <PriceSection
              onChoosePlan={onChoosePlan}
              currentPrice={currentPrice}
              currentPaymentPeriod={currentPayment?.period || currentPayment?.type}
              isCurrentSubscribe={isCurrentSubscribe}
              isResponsive={isResponsive}
              planType={planType}
              subscribeType={subscribeType}
              term={term}
              termsCount={termsCount}
              goldCount={counts.goldCounts}
              isMaxHeight={isMaxHeight}
              countPlans={countPlans}
              isSelect={isSelect}
              plan={plan}
              isGoldSubscribe={isGoldSubscribe}
              currentPriceAnnual={currentPriceAnnual}
              currentPriceLifetime={currentPriceLifetime}
            />
          </CommonPriceContainer>

          {isShowButton && (
            <StyledButton
              disabled={isDisabled}
              isLoading={isLoading}
              onClick={() =>
                hasSubscriptionDowngrade
                  ? handleCancelSubscriptionDowngrade()
                  : toggleSubscription(options, isCurrentSubs)
              }
              variant={isPrimary ? 'primary' : 'secondary'}
              size="small">
              {currentButtonText}
            </StyledButton>
          )}
        </PlanInformation>
        {isResponsive && activePeriod === 'gold' && (
          <BonusSection isProduct={false} setIsShowBonusModal={setIsShowBonusModal} />
        )}
        {isResponsive && (
          <ResponsiveTabs
            isShow={isShow}
            activePeriod={activePeriod}
            setActiveIndex={setActiveIndex}
            setActivePeriod={setActivePeriod}
          />
        )}
        {!isResponsive ? (
          <CapabilityList>
            {capabilities.map((item, index) => (
              <CapabilityItem $include={item.include} key={index}>
                <IconContainer>{item.include ? <CheckMark size={24} /> : <Cross size={24} />}</IconContainer>
              </CapabilityItem>
            ))}
          </CapabilityList>
        ) : (
          <ResponsiveCapability capabilitiesResponsive={capabilitiesResponsive} />
        )}
      </Container>
    </div>
  );
};

export default SubscriptionItem;

const Container = styled.div<{ type: string; isShow: boolean; isResponsive: boolean }>`
  margin-top: ${({ isResponsive }) => (isResponsive ? '30px' : 'unset')};
  width: ${({ isShow }) => (!isShow ? '255px' : '100%')};
  display: ${({ isShow, isResponsive }) => (isShow ? 'flex' : !isResponsive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: start;
  height: 100%;
  text-align: start;
  position: relative;
  padding: 0px 18px 31px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  border-top: ${({ type }) => (type === 'gold' ? '35px' : '2px')} solid
    ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-right: 4px solid ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-bottom: 4px solid
    ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-left: 4px solid ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-radius: 16px;

  @media (max-width: 1180px) {
    border: 0;
    padding: 0px 0px 31px;
  }
`;

const CommonPriceContainer = styled.div<{ isResponsive: boolean; isMaxHeight: boolean }>`
  display: ${({ isResponsive }) => (isResponsive ? 'flex' : 'block')};
  gap: 7px;
  margin-top: ${({ isMaxHeight }) => (isMaxHeight ? '15px' : '20px')};
`;

const AnimatedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => typography.title_4_bold_24};
  margin-bottom: 20px;
`;

const MostEffectiveTable = styled.p`
  z-index: 1;
  position: absolute;
  top: -30px;
  left: 28%;
  ${({ theme: { typography } }) => typography.body_large_bold_16}

  color: #FFFFFF;
`;

const Table = styled.p<{ isResponsive: boolean }>`
  text-align: center;
  background: ${({ isResponsive, theme: { colors } }) => (isResponsive ? colors.neutrals[9] : 'unset')};
  padding: ${({ isResponsive }) => (isResponsive ? '8px 12px' : 'unset')};
  border-radius: ${({ isResponsive }) => (isResponsive ? '12px' : 'unset')};
  ${({ theme: { typography } }) => typography.body_large_extrabold_16}
  color: ${({ theme: { colors } }) => colors.primary[1]};
  margin-bottom: 10px;
  /* position: absolute; */
  width: ${({ isResponsive }) => (isResponsive ? '100%' : 'unset')};
  top: ${({ isResponsive }) => (isResponsive ? '260px' : 'unset')};
  left: ${({ isResponsive }) => (isResponsive ? 'unset' : '16%')};
`;

const CapabilityList = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: start;
  margin-bottom: 24px;
`;

const PlanInformation = styled.div`
  /* position: sticky; */
  top: 0;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  width: 100%;
  margin-bottom: 10px;
  padding-top: 15px;
`;

const CapabilityItem = styled.div<{ $include: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24.5px;
  color: ${({ theme: { colors }, $include }) => !$include && colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
`;

const TitleWithLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  padding: ${({ variant }) => (variant === 'secondary' ? '7px 32px' : '8px 32px')};
  margin-top: 16px;
  margin-bottom: 10px;

  @media (min-width: 1350px) {
    white-space: nowrap;
  }
`;
