// import { RadioButton } from 'UI';
import React, { FC } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { PaymentOptions } from 'store/ducks/courses/types';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

interface Props {
  isResponsive?: boolean;
}

const PaymentOptionsSelector: FC<Props> = ({ isResponsive }) => {
  const dispatch = useAppDispatch();
  const paymentOption = useAppSelector(selectors.courses.selectPaymentOption);

  const handleOptionChange = (option: string) => {
    dispatch(actions.courses.setPaymentOption(option));
  };

  return (
    <RadioButtonContainer isResponsive={isResponsive}>
      {!isResponsive && <RadioContainerTitle>Enroll / Purchase</RadioContainerTitle>}
      <RadioInputContainer>
        <RadioInput
          type="radio"
          id="payment_plan"
          name="payment"
          style={{ transform: 'scale(1.5)', marginRight: '16px' }}
          value={'plan'}
          checked={paymentOption === 'paymentPlan'}
          onChange={() => handleOptionChange('paymentPlan')}
        />
        <CustomRadio isChecked={paymentOption === PaymentOptions.PAYMENT_PLAN}></CustomRadio>
        {'Payment plan'}
      </RadioInputContainer>
      <RadioParagraph>The cost of the tariff will be divided into 12 different monthly installments.</RadioParagraph>

      <RadioInputContainer>
        <RadioInput
          type="radio"
          id="onetime"
          name="payment"
          style={{ transform: 'scale(1.5)', marginRight: '16px' }}
          value={'onetime'}
          checked={paymentOption === 'onetime'}
          onChange={() => handleOptionChange('onetime')}
        />
        <CustomRadio isChecked={paymentOption === PaymentOptions.ONETIME}></CustomRadio>
        {'One time payment'}
      </RadioInputContainer>
      <RadioParagraph>One-off payment of the full price.</RadioParagraph>
    </RadioButtonContainer>
  );
};

export default PaymentOptionsSelector;

const RadioButtonContainer = styled.div<{ isResponsive: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  position: ${({ isResponsive }) => (isResponsive ? 'unset' : 'absolute')};
  top: 360px;
  left: 30px;
`;

const RadioContainerTitle = styled.h4`
  ${({ theme: { typography } }) => typography.title_5_bold_20}
  color: #DF6438;
`;

const RadioInputContainer = styled.label`
  margin-top: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${({ theme: { typography } }) => css`
    ${typography.headline_semibold_18}

    ${respondToWidth.s`
      ${typography.body_basic_semibold_14}
  `}
  `};
`;

const RadioInput = styled.input`
  display: none;
`;

const CustomRadio = styled.span<{ isChecked: boolean }>`
  width: 24px;
  height: 24px;
  border: 2px solid #f6f6f6;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;

  ${({ isChecked }) =>
    isChecked &&
    css`
      background-color: #f6f6f6;
      border-color: #f6f6f6;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #df6438;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

const RadioParagraph = styled.p`
  ${({ theme: { typography } }) => typography.body_basic_regular_14}
  width: 240px;
  margin-left: 32px;
  margin-top: 9px;
`;
