import Button from 'UI/Button';
import { CourseDto, PaymentDtoStatusEnum } from 'api/generated';
import { SpamIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { PauseModal } from 'components';
import { levelMapping, paymentTypeMapping } from 'constant';
import { useAsyncAction, useGetIsMobile, useNotifications, useOnClickOutside } from 'hooks';
import { FC, HTMLAttributes, MouseEventHandler, useRef, useState } from 'react';
import { dayjs } from 'services';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import Tooltip from '../../components/Tooltip/Tooltip';
import StyledText from '../StyledText';

type CourseProps = {
  onStatusClick: () => void;
  course?: CourseDto | null;
  disabled?: boolean;
  isLastItem?: boolean;
  onClick?: () => void;
  isShowChangePlanButton?: boolean;
} & HTMLAttributes<HTMLDivElement>;

interface StatusProps {
  isPause?: boolean;
}

const Course: FC<CourseProps> = ({
  course,
  onStatusClick,
  onClick,
  disabled,
  isLastItem,
  isShowChangePlanButton,
  ...props
}) => {
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const [isShowPauseModal, setIsShowPauseModal] = useState(false);
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const [isShowNameTooltip, setIsShowNameTooltip] = useState(false);
  const { isMobile } = useGetIsMobile();
  const rootRef = useRef<HTMLDivElement>(null);
  const [unpauseSubscription, isLoading] = useAsyncAction(actions.payments.unpauseSubscription);
  // @ts-ignore
  const [unpauseInstallment, isLoadingInstallment] = useAsyncAction(actions.payments.unpauseInstallment);
  const { successToast, errorToast } = useNotifications();

  const onPress = () => {
    setIsShowNameTooltip(true);
    !disabled && onClick && onClick();
  };

  useOnClickOutside(rootRef, () => setIsShowNameTooltip(false));

  if (!course) {
    return <Root />;
  }

  const handleStatusClick: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(actions.courses.setIdCourse(course.id));
    onStatusClick();
  };

  const isPurchase = course.currentPayment.type === 'Purchase';
  const isNextPayment = Boolean(course.nextPayment);
  const isPause = Boolean(course.currentPayment.onPauseUntil);
  const isPauseCertificate = course.currentPayment.status === PaymentDtoStatusEnum.Trialing;
  const { isActive } = course;
  const isPauseUntil = course.currentPayment.onPauseUntil;

  const getDateText = (text: string) =>
    course.nextPayment?.activeFrom ? ` ${text} ${dayjs(course.nextPayment?.activeFrom).format('MM.DD')}` : '';

  const getDatePauseText = (text: string) =>
    course.currentPayment.onPauseUntil ? ` ${text} ${dayjs(course.currentPayment.onPauseUntil).format('MM.DD')}` : '';

  const currentPaymentLevelText = course.currentPayment.level ? levelMapping[course.currentPayment.level] : 'Trial';

  const canceledSubscriptionText = `${currentPaymentLevelText}${getDateText('until')}`;
  const pauseSubscriptionText = `${currentPaymentLevelText}${getDatePauseText('on pause until')}`;

  const subscriptionText = isPauseUntil
    ? pauseSubscriptionText
    : isNextPayment
    ? canceledSubscriptionText
    : currentPaymentLevelText;
  const levelText = isPurchase ? 'Lifetime' : subscriptionText;
  const nextPaymentTypeText = course.nextPayment ? paymentTypeMapping[course.nextPayment.type] : '';

  const tooltipText = course.nextPayment?.level
    ? `Your subscription will switch to ${levelMapping[course.nextPayment.level]} ${nextPaymentTypeText} ${getDateText(
        'on',
      )}`
    : '';

  const isShowFullName = isShowNameTooltip && isMobile;

  const handleUnpause = async (courseId: string) => {
    try {
      await unpauseSubscription({ courseId });
      successToast('Successfully removed from pause');
      dispatch(actions.courses.setPauseSubscription(false));
    } catch (error) {
      errorToast((error as AxiosError).message || 'Something went wrong');
    }
  };

  const handleUnpauseInstallment = async (courseId: string) => {
    try {
      await unpauseInstallment({ courseId });
      successToast('Successfully removed from pause');
      dispatch(actions.courses.setPauseCertificate(false));
    } catch (error) {
      errorToast((error as AxiosError).message || 'Something went wrong');
    }
  };

  return (
    <Root {...props} ref={rootRef} onClick={onPress}>
      <Wrapper>
        <Indicator $isActive={isActive} $isDarkMode={isDarkMode} />
        <Title font="body_large_bold_16">{course.name}</Title>
      </Wrapper>
      <ButtonWrapper>
        {isPauseCertificate && (
          <PauseButton
            isLoading={isLoadingInstallment}
            onClick={() => handleUnpauseInstallment(course.id)}
            variant="secondary">
            Activate
          </PauseButton>
        )}
        {isPause && (
          <PauseButton isLoading={isLoading} onClick={() => handleUnpause(course.id)} variant="secondary">
            Activate
          </PauseButton>
        )}
        {!isPause && !isPauseCertificate && isShowChangePlanButton && (
          <ChangePlanButton onClickCapture={handleStatusClick} disabled={!isActive} variant="secondary">
            Change Plan
          </ChangePlanButton>
        )}
        <StatusWrapper isPause={isPause || isPauseCertificate}>
          <StatusContainer>
            <Status>{levelText}</Status>
          </StatusContainer>
        </StatusWrapper>
        {isNextPayment && (
          <div style={{ marginLeft: '10px' }}>
            <Tooltip isAbove={isLastItem} title="Changing subscriptions" text={tooltipText}>
              <SpamIcon width={24} height={24} color={colors.primary[1]} />
            </Tooltip>
          </div>
        )}
        {isPause && !respondToWidth.s && (
          <div>
            <Tooltip isAbove={isLastItem} title="Changing subscriptions" text={tooltipText}>
              <SpamIcon width={24} height={24} color={colors.primary[1]} />
            </Tooltip>
          </div>
        )}
        {isShowPauseModal && <PauseModal isModalVisible={isShowPauseModal} setModalVisible={setIsShowPauseModal} />}
      </ButtonWrapper>
      {isShowFullName && (
        <FullTitle $isAbove={isLastItem} font="footnote_semibold_12">
          {course.name}
        </FullTitle>
      )}
    </Root>
  );
};

export default Course;

const Root = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;

    padding: 16px 12px 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    cursor: pointer;

    background-color: ${colors.neutrals[11]};
    border: 1px solid ${colors.neutrals[9]};
    border-radius: 12px;

    position: relative;
  `}
`;

const Wrapper = styled.div`
  width: fit-content;
  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr;
  grid-gap: 12px;
`;

const PauseButton = styled(Button)`
  padding: 3px 20px 3px 20px;
  margin-right: 25px;
  ${respondToWidth.s`
    margin-top: 10px;
    margin-right: 0;
    padding: 0px 10px 0px 10px;
  `}
`;

const ChangePlanButton = styled(Button)`
  padding: 3px 20px 3px 20px;
  margin-right: 20px;
  ${respondToWidth.s`
    margin-top: 10px;
    margin-right: 0;
    white-space: nowrap;
    padding: 0px 10px 0px 10px;
  `}
`;

const Indicator = styled.span<{ $isActive?: boolean; $isDarkMode?: boolean }>`
  ${({ theme: { colors }, $isActive, $isDarkMode }) => css`
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${$isDarkMode ? colors.neutrals[8] : colors.neutrals[10]};
    border-radius: 50%;

    ${$isActive &&
    css`
      ::after {
        content: '';
        width: 10px;
        height: 10px;

        background-color: ${colors.primary[1]};
        border-radius: 50%;
      }
    `}
  `}
`;

const Title = styled(StyledText)`
  max-width: 658px;
  width: 100%;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StatusWrapper = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  width: ${({ isPause }) => (!isPause ? '125px' : '200px')};
  justify-content: center;

  ${respondToWidth.s`
    width: unset;
    text-align: center;
  `};
`;

const Status = styled.p<StatusProps>`
  ${({ theme: { colors } }) => css`
    white-space: nowrap;

    color: ${colors.neutrals[4]};
  `}

  ${respondToWidth.s`
    white-space: unset;
  `};
`;

const StatusContainer = styled.div`
  ${({ theme: { colors } }) => css`
    border-bottom: 1px dashed ${colors.neutrals[4]};
  `}
`;

// const Buttons = styled.button`
//   padding: 0 12px;
//   margin: 0;
//   border: none;
//   outline: none;
//   background-color: inherit;
// `;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  ${respondToWidth.s`
    flex-direction: column-reverse;
    width: 41%;
  `}
`;

const FullTitle = styled(StyledText)<{ $isAbove?: boolean }>`
  width: calc(100% - 16px);
  background-color: ${({ theme: { colors } }) => colors.neutrals[1]};
  color: ${({ theme: { colors } }) => colors.neutrals[11]};
  padding: 12px 16px;
  border-radius: 12px;
  position: absolute;
  left: 8px;
  bottom: ${({ $isAbove }) => ($isAbove ? '90px' : '4px')};
  transform: translateY(100%);
  z-index: 1;
`;
