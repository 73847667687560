import { CapabilityType } from 'UI/SubscriptionItem/SubscriptionItem';
import { CheckMark, Cross } from 'assets/icons';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ResponsiveCapabilityProps {
  capabilitiesResponsive: CapabilityType[];
}

export const ResponsiveCapability: FC<ResponsiveCapabilityProps> = ({ capabilitiesResponsive }) => {
  return (
    <>
      <ResponsiveCapabilityContainer>
        <ResponsiveTitle position={'left'}>Feature</ResponsiveTitle>
        <ResponsiveTitle position={'right'}>Included?</ResponsiveTitle>
        {capabilitiesResponsive.map((item, index) => (
          <CapabilityItemResponsive $include={item.include} key={item.title} index={index}>
            <div style={{ display: 'flex' }}>
              <CapabilityResponsiveWrapper>{item.title}</CapabilityResponsiveWrapper>
            </div>
            <IconContainer>{item.include ? <CheckMark size={24} /> : <Cross size={24} />}</IconContainer>
          </CapabilityItemResponsive>
        ))}
      </ResponsiveCapabilityContainer>
    </>
  );
};

const ResponsiveCapabilityContainer = styled.div`
  border-top: 30px solid #df6438;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  width: 100%;
`;

const CapabilityResponsiveWrapper = styled.div`
  display: flex;
`;

const CapabilityItemResponsive = styled.div<{ $include: boolean; index: number }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  background-color: ${({ theme: { colors }, index }) => (index % 2 !== 0 ? colors.neutrals[11] : colors.neutrals[13])};
  color: ${({ theme: { colors }, $include }) => !$include && colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ResponsiveTitle = styled.p<{ position: string }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  position: absolute;
  top: -22px;
  left: ${({ position }) => (position === 'left' ? '10px' : 'unset')};
  right: ${({ position }) => (position === 'right' ? '10px' : 'unset')};
  color: white;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
`;
