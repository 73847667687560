import PriceLabel from 'UI/PriceLabel';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { SubscribeDtoTypeEnum } from 'api/generated';
import { ActivePlan, Plan } from 'components/Modals/ChooseProductModal/components/Products/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

interface PriceSectionProps {
  term: TermEnum;
  subscribeType: SubscribeDtoTypeEnum;
  termsCount: number;
  planType: ActivePlan | undefined;
  isResponsive: boolean;
  goldCount?: number;
  countPlans?: number;
  isMaxHeight?: boolean | undefined;
  onChoosePlan: any;
  currentPrice: number;
  isCurrentSubscribe: boolean;
  currentPriceLifetime: number;
  currentPaymentPeriod: string | undefined;
  currentPriceAnnual: number;
  isGoldSubscribe: boolean;
  isSelect: boolean;
  plan: any;
}

export const PriceSection: FC<PriceSectionProps> = ({
  isResponsive,
  subscribeType,
  termsCount,
  planType,
  goldCount,
  countPlans,
  isMaxHeight,
  onChoosePlan,
  currentPrice,
  isCurrentSubscribe,
  currentPriceLifetime,
  currentPaymentPeriod,
  currentPriceAnnual,
  isSelect,
  plan,
}) => {
  const calculateCurrentPrice = (
    amount: number,
    defaultCouponDiscount?: number,
    defaultCouponDiscountType?: string,
  ) => {
    if (defaultCouponDiscount && amount - defaultCouponDiscount >= 1 && defaultCouponDiscountType === 'amount') {
      return amount - defaultCouponDiscount;
    }

    if (defaultCouponDiscount && defaultCouponDiscountType === 'percentage') {
      const discountAmount = amount * (defaultCouponDiscount / 100);

      if (currentPrice - discountAmount >= 1) {
        return amount - discountAmount;
      }
    }

    return amount;
  };

  // console.log('isCur => ', isCurrentSubscribe);
  // console.log('Current => ', currentPaymentPeriod);

  return (
    <>
      {plan.priceMonthly && (
        <>
          <PriceWrapper isResponsive={isResponsive}>
            {isCurrentSubscribe && currentPaymentPeriod === TermEnum.MONTHLY && <YourPlan>Your plan</YourPlan>}
            <PriceContainer
              termCount={termsCount}
              activePlan={planType}
              subscribeType={subscribeType}
              term={TermEnum.MONTHLY}
              isSelect={isSelect}
              isResponsive={isResponsive}
              onClick={() => onChoosePlan(plan.priceMonthly, TermEnum.MONTHLY, subscribeType)}>
              <StyledPriceLabel
                termCount={termsCount}
                countPlans={countPlans}
                isMaxHeight={isMaxHeight}
                currentPrice={calculateCurrentPrice(
                  currentPrice,
                  plan.priceMonthly?.defaultCoupon?.discountAmount,
                  plan.priceMonthly?.defaultCoupon?.discountType,
                )}
                oldPrice={plan.priceMonthly?.defaultCoupon?.discountAmount ? currentPrice : undefined}
                term={TermEnum.MONTHLY}
              />
            </PriceContainer>
          </PriceWrapper>
        </>
      )}
      {plan.priceAnnual && (
        <PriceWrapper isResponsive={isResponsive}>
          {isCurrentSubscribe && currentPaymentPeriod === TermEnum.YEARLY && <YourPlan>Your plan</YourPlan>}
          <PriceContainer
            termCount={termsCount}
            activePlan={planType}
            subscribeType={subscribeType}
            term={TermEnum.YEARLY}
            isResponsive={isResponsive}
            onClick={() => onChoosePlan(plan.priceAnnual, TermEnum.YEARLY, subscribeType)}>
            <StyledPriceLabel
              termCount={termsCount}
              isMaxHeight={isMaxHeight}
              goldCount={goldCount}
              currentPrice={calculateCurrentPrice(
                currentPriceAnnual,
                plan.priceAnnual?.defaultCoupon?.discountAmount,
                plan.priceAnnual?.defaultCoupon?.discountType,
              )}
              oldPrice={plan.priceAnnual?.defaultCoupon?.discountAmount ? currentPriceAnnual : undefined}
              term={TermEnum.YEARLY}
            />
          </PriceContainer>
        </PriceWrapper>
      )}
      {plan.purchasePrice && (
        <PriceWrapper isResponsive={isResponsive}>
          {isCurrentSubscribe && currentPaymentPeriod === 'Purchase' && <YourPlan>Your plan</YourPlan>}
          <PriceContainer
            termCount={termsCount}
            activePlan={planType}
            subscribeType={subscribeType}
            term={TermEnum.LIFE_TIME}
            isResponsive={isResponsive}
            onClick={() => onChoosePlan(plan.purchasePrice as Plan, TermEnum.LIFE_TIME, subscribeType)}>
            <StyledPriceLabel
              isMaxHeight={isMaxHeight}
              termCount={termsCount}
              goldCount={goldCount}
              countPlans={countPlans}
              currentPrice={calculateCurrentPrice(
                currentPriceLifetime,
                //@ts-ignore
                plan.purchasePrice?.defaultCoupon?.discountAmount,
                //@ts-ignore
                plan.purchasePrice?.defaultCoupon?.discountType,
              )}
              //@ts-ignore
              oldPrice={plan.purchasePrice?.defaultCoupon?.discountAmount ? currentPriceLifetime : undefined}
              term={TermEnum.LIFE_TIME}
            />
          </PriceContainer>
        </PriceWrapper>
      )}
    </>
  );
};

const PriceWrapper = styled.div<{ isResponsive: boolean }>`
  position: relative;
  width: ${({ isResponsive }) => (isResponsive ? '100%' : 'unset')};
`;

const PriceContainer = styled.div<{
  termCount: number;
  activePlan: { planType?: string; term: string } | undefined;
  subscribeType: string;
  term: string;
  isSelect?: boolean;
  isResponsive: boolean;
}>`
  padding: 8px 8px;
  background: ${({ theme: { colors } }) => colors.neutrals[9]};
  border: ${({ subscribeType, activePlan, isSelect, term, theme: { colors } }) =>
    (activePlan && subscribeType === activePlan.planType && term === activePlan.term) || isSelect
      ? '2px solid #DF6438'
      : `2px solid ${colors.neutrals[9]}`};
  border-radius: 12px;
  align-items: center;
  margin: 12px 0px;
  display: ${({ isResponsive }) => (isResponsive ? 'flex' : 'block')};
  justify-content: ${({ isResponsive }) => (isResponsive ? 'center' : 'unset')};

  @media (max-width: 550px) {
    padding: ${({ termCount }) => {
      return termCount !== 1 ? '12px 8px' : '8px 8px';
    }};
  }
`;

const StyledPriceLabel = styled(PriceLabel)<{
  isMaxHeight: boolean | undefined;
  goldCount?: number;
  countPlans?: number;
}>`
  ${({ theme: { colors, typography } }) => css`
    gap: 8px;

    color: ${colors.neutrals[1]};
    ${typography.headline_semibold_18};
  `}

  display: ${({ isMaxHeight, goldCount }) => (isMaxHeight && goldCount === 1 ? 'flex' : 'flex')};
  flex-direction: ${({ isMaxHeight, goldCount }) => (isMaxHeight && goldCount === 1 ? 'column' : 'unset')};

  @media (max-width: 560px) {
    ${({ theme: { typography } }) => css`
      ${typography.body_large_bold_16}
      font-weight: 600;
      line-height: 14px;
    `}
  }
`;

const YourPlan = styled.p`
  ${({ theme: { typography } }) => typography.footnote_semibold_12}
  background: ${({ theme: { colors } }) => colors.primary[1]};
  padding: 4px 8px;
  border-radius: 12px;
  width: 82px;
  color: ${({ theme: { colors } }) => colors.neutrals[11]};
  position: absolute;
  text-align: center;
  top: -15px;
  right: 5px;
`;
