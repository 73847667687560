import SubscriptionItem from 'UI/SubscriptionItem';
import { BonusSection } from 'UI/SubscriptionItem/components/BonusSection/BonusSection';
import { PaymentDtoTypeEnum, SubscribeDtoTypeEnum } from 'api/generated';
import arrowDow2 from 'assets/images/arrow-down2.png';
import arrowDown from 'assets/images/arrow-down.png';
import shape from 'assets/images/shape.png';
import raiseСup from 'assets/json/pricing-animate-1.json';
import throwInBasket from 'assets/json/pricing-animate-2.json';
import dribbling from 'assets/json/pricing-animate-3.json';
import throwInBasketDark from 'assets/json/pricing_icon_1_V1(square).json';
import dribblingDark from 'assets/json/pricing_icon_2_V1(square).json';
import raiseСupDark from 'assets/json/pricing_icon_3_V1(square).json';
import {
  bronzeCapabilities,
  bronzeCapabilitiesResponsive,
  commonCapabilities,
  goldCapabilities,
  goldCapabilitiesResponsive,
  levelGradeMapping,
  silverCapabilities,
  silverCapabilitiesResponsive,
} from 'constant';
import React, { FC, useEffect, useState } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';

import { TrialText } from './components';
import { ActivePlan, Plan, ProductsProps } from './types';

const Products: FC<ProductsProps> = ({
  monthlyPrices,
  annualPrices,
  purchasePrice,
  currentPayment,
  nextPayment,
  setIsShowBonusModal,
  courseId,
  isTrialStarting,
  isSubscriptionUpdating,
  toggleSubscription,
  startUnsubscribeProcess,
  cancelSubscriptionDowngrade,
  startTrial,
  mostEffectiveLabel,
}) => {
  const isTrialText = currentPayment?.level === 'none' || nextPayment?.type === 'Free';
  const [activeIndex, setActiveIndex] = useState(0);
  const [activePeriod, setActivePeriod] = useState('gold');
  const [planType, setPlanType] = useState<ActivePlan>();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);

  const isGoldPurchase = currentPayment?.type === PaymentDtoTypeEnum.Purchase;
  const hasSubscription = currentPayment?.type === PaymentDtoTypeEnum.Subscription;

  const isResponsive = screenWidth <= 1180;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const plans: Array<Plan> = [
    {
      priceMonthly: monthlyPrices?.gold,
      priceAnnual: annualPrices?.gold,
      purchasePrice: purchasePrice?.gold,
      type: SubscribeDtoTypeEnum.Gold,
      capabilities: goldCapabilities,
      capabilitiesResponsive: goldCapabilitiesResponsive,
      animationData: raiseСup,
      darkAnimationData: throwInBasketDark,
    },

    {
      priceMonthly: monthlyPrices?.silver,
      priceAnnual: annualPrices?.silver,
      purchasePrice: purchasePrice?.silver,
      type: SubscribeDtoTypeEnum.Silver,
      capabilities: silverCapabilities,
      capabilitiesResponsive: silverCapabilitiesResponsive,
      animationData: throwInBasket,
      darkAnimationData: dribblingDark,
    },

    {
      priceMonthly: monthlyPrices?.bronze,
      priceAnnual: annualPrices?.bronze,
      purchasePrice: purchasePrice?.bronze,
      type: SubscribeDtoTypeEnum.Bronze,
      capabilities: bronzeCapabilities,
      capabilitiesResponsive: bronzeCapabilitiesResponsive,
      animationData: dribbling,
      darkAnimationData: raiseСupDark,
    },
  ];

  let countPlans: number;

  const getPlan = (type: string) => plans.find((plan) => plan.type === type);

  const goldPlan = getPlan(SubscribeDtoTypeEnum.Gold);
  // const silverPlan = getPlan(SubscribeDtoTypeEnum.Silver);
  // const bronzePlan = getPlan(SubscribeDtoTypeEnum.Bronze);

  const countPrices = (plan: Plan | undefined) => {
    if (!plan) return;

    return (
      (plan.priceAnnual ? 1 : 0) +
      (plan.priceMonthly ? 1 : 0) +
      (plan.type === SubscribeDtoTypeEnum.Gold && plan.purchasePrice !== undefined ? 1 : 0)
    );
  };

  // const goldCounts = countPrices(goldPlan);

  const counts = {
    // goldCount: goldCounts,
    // silverCount: silverCounts,
    // bronzeCount: bronzeCounts,
  };

  const getCapabilitiesMargin = () => {
    let actualPlanPricesMargin = 0;

    plans.forEach(() => {
      const countPlanPrices = countPrices(goldPlan);

      if (countPlanPrices === 1) {
        actualPlanPricesMargin = 416;
      }

      if (countPlanPrices === 2) {
        actualPlanPricesMargin = 473;
      }

      if (countPlanPrices === 3) {
        actualPlanPricesMargin = 526;
      }
    });

    return `${actualPlanPricesMargin}px`;
  };

  return (
    <Root style={{ marginTop: isResponsive ? '-557px' : '15px' }}>
      <Wrapper>
        <Container $isShowPurchase={false} isResponsive={isResponsive}>
          {!isResponsive && (
            <div style={{ width: '70px', height: '70px', position: 'absolute', top: '260px', left: '100px' }}>
              <img src={shape} alt="shape" style={{ filter: isDarkMode ? 'invert(1)' : 'unset' }} />
            </div>
          )}
          <ImageDivArrowRight>
            <img src={arrowDow2} alt="arrow" style={{ filter: isDarkMode ? 'invert(1)' : 'unset' }} />
          </ImageDivArrowRight>

          <ImageDivArrowLeft>
            <img src={arrowDown} alt="arrow" style={{ filter: isDarkMode ? 'invert(1)' : 'unset' }} />
          </ImageDivArrowLeft>

          {(currentPayment?.level === 'none' || currentPayment?.level === 'gold') && (
            <BonusSection isProduct={true} setIsShowBonusModal={setIsShowBonusModal} />
          )}
          {plans && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: getCapabilitiesMargin(),
              }}>
              {!isResponsive &&
                commonCapabilities.map((cap, index) => (
                  <div style={{ alignItems: 'center', width: '230px' }} key={index}>
                    <CapabilitiesContainer>{cap.title}</CapabilitiesContainer>
                  </div>
                ))}
            </div>
          )}
          {plans.map((plan, index) => {
            const termsCount = (plan.priceAnnual ? 1 : 0) + (plan.priceMonthly ? 1 : 0) + (plan.purchasePrice ? 1 : 0);

            const isLowest = currentPayment?.level
              ? levelGradeMapping[currentPayment.level] > levelGradeMapping[plan.type]
              : false;

            return (
              <SubscriptionItem
                key={index}
                isResponsive={isResponsive}
                plan={plan}
                termsCount={termsCount}
                setIsShowBonusModal={setIsShowBonusModal}
                indexPlan={index}
                counts={counts}
                animationData={isDarkMode ? plan.darkAnimationData : plan.animationData}
                setPlanType={setPlanType}
                planType={planType}
                countPlans={countPlans}
                isMaxHeight={false}
                isShow={activeIndex === index}
                activePeriod={activePeriod}
                setActivePeriod={setActivePeriod}
                setActiveIndex={setActiveIndex}
                cancelSubscriptionDowngrade={cancelSubscriptionDowngrade}
                toggleSubscription={toggleSubscription}
                isLowest={isLowest}
                courseId={courseId}
                isCurrentSubscription={currentPayment?.level === plan.type}
                isSubscriptionUpdating={isSubscriptionUpdating}
                currentPayment={currentPayment}
                hasSubscriptionDowngrade={Boolean(nextPayment)}
                nextPayment={nextPayment?.level === plan.type ? nextPayment : undefined}
                isShowButton={!isGoldPurchase}
                hasSubscription={hasSubscription}
                mostEffectiveLabel={mostEffectiveLabel}
              />
            );
          })}
        </Container>
        {isTrialText && (
          <TrialText
            isDowngrade={nextPayment?.type === 'Free'}
            cancelSubscriptionDowngrade={() => cancelSubscriptionDowngrade(courseId, true)}
            currentPayment={currentPayment}
            startTrial={() => startTrial(courseId)}
            isTrialStarting={isTrialStarting}
            startUnsubscribeProcess={startUnsubscribeProcess}
          />
        )}
      </Wrapper>
    </Root>
  );
};

export default Products;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  grid-gap: 32px;

  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const ImageDivArrowRight = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 40px;
  right: 185px;
  z-index: 1;

  @media (max-width: 1180px) {
    top: 190px;
    right: 30px;
  }
`;

const ImageDivArrowLeft = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 40px;
  left: 185px;
  z-index: 1;

  @media (max-width: 1180px) {
    top: 140px;
    left: 90px;
  }

  @media (max-width: 570px) {
    top: 130px;
    left: 30px;
  }
`;

const CapabilitiesContainer = styled.div`
  ${({ theme: { typography } }) => typography.body_large_semibold_16}
  margin-bottom: 21px;
`;

const Container = styled.div<{ $isShowPurchase: boolean; isResponsive: boolean }>`
  display: ${({ isResponsive }) => (isResponsive ? 'unset' : 'flex')};
  height: max-content;
  gap: 20px;

  ${({ $isShowPurchase }) =>
    $isShowPurchase
      ? css`
          margin: 0 auto auto;
          display: grid;
          grid-template-columns: 352px;
        `
      : css``}

  @media (max-width: 1180px) {
    width: 500px;
  }

  @media (max-width: 570px) {
    width: 370px;
  }

  @media (max-width: 370px) {
    width: 320px;
  }
`;
