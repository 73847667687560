import { Checkbox } from 'UI';
import { CheckboxVariant } from 'UI/Checkbox/Checkbox';
import { CouponDiscountTypeEnum } from 'api/generated';
import { useAsyncAction } from 'hooks';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { getReducedPrice } from 'utils';

export const SubscribeDtoTypeEnum = {
  Bronze: 'bronze',
  Silver: 'silver',
  Gold: 'gold',
} as const;

export type SubscribeDtoType = typeof SubscribeDtoTypeEnum[keyof typeof SubscribeDtoTypeEnum];

interface SuccessCouponProps {
  subscribeType: SubscribeDtoType;
  courseName: string;
  oldAmount?: number;
  amount: number;
  promoCode?: string;
  setClientSecret: Dispatch<SetStateAction<string | undefined>> | undefined;
  setInvoiceId: Dispatch<SetStateAction<string>>;
  setIsLoadingChangeFirstCoupon: Dispatch<SetStateAction<boolean>>;
  discountAmount: number;
  invoiceId: string;
  discountValue: number;
  removeCoupon: () => void;
  reset: UseFormReset<any>;
}

const SuccessCoupon: FC<SuccessCouponProps> = ({
  amount,
  discountValue,
  promoCode,
  invoiceId,
  subscribeType,
  setClientSecret,
  setInvoiceId,
  setIsLoadingChangeFirstCoupon,
}) => {
  //@ts-ignore
  const [changeUseFirstCoupon] = useAsyncAction(actions.payments.changeUseFirstPromoCode);
  const dispatch = useAppDispatch();
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const selectedCourse = useAppSelector(selectors.courses.selectCourse);
  const course = selectedCourse || activeCourse;

  // @ts-ignore
  const isFirstDiscount = !!course?.prices?.purchase?.[subscribeType]?.firstMonthDiscountCouponId;
  const firstMonthDiscountAmount = Number(
    // @ts-ignore
    course?.prices?.purchase?.[subscribeType].firstMonthDiscountCoupon?.discountAmount,
  );

  const firstMonthDiscountType =
    // @ts-ignore
    course?.prices?.purchase?.[subscribeType]?.firstMonthDiscountCoupon?.discountType;

  const amountFirstDiscountType = firstMonthDiscountType === CouponDiscountTypeEnum.Amount;

  const totalAmount = getReducedPrice(amount - discountValue);
  const perMonth = getReducedPrice(totalAmount / 12);

  const [isFirstMonthDiscount, setIsFirstMonthDiscount] = useState<boolean>(isFirstDiscount);

  const getPriceWithFirthMonthDiscount = () => {
    if (firstMonthDiscountType === CouponDiscountTypeEnum.Amount) {
      return getReducedPrice(perMonth - firstMonthDiscountAmount);
    } else {
      const discount = (perMonth * firstMonthDiscountAmount) / 100;
      return getReducedPrice(perMonth - discount);
    }
  };

  const initialAmount = isFirstMonthDiscount ? getPriceWithFirthMonthDiscount() : perMonth;

  const checkboxLabelForDiscountAmount = `$${firstMonthDiscountAmount}
   Down, Start Today!`;

  const checkboxLabelForDiscountPercentage = `$${(perMonth * firstMonthDiscountAmount) / 100}
   Down, Start Today!`;

  const toggleIsFirstMonthDiscount = async () => {
    if (!setClientSecret) return;
    setIsLoadingChangeFirstCoupon(true);
    dispatch(actions.courses.setUseFirstMonthDiscount(!isFirstDiscount));
    setIsFirstMonthDiscount(!isFirstMonthDiscount);

    const { clientSecret, invoiceId: newInvoiceId } = await changeUseFirstCoupon({
      promocode: promoCode,
      invoiceId,
      useFirstMonthDiscount: !isFirstMonthDiscount,
    });
    setClientSecret(clientSecret);
    setInvoiceId(newInvoiceId);
    setIsLoadingChangeFirstCoupon(false);
  };

  return (
    <div>
      <SuccessCouponContainer>
        <CouponWrapper>
          <TextCoupon>Product Total</TextCoupon>
          <TextCoupon2>{`$${amount}`}</TextCoupon2>
        </CouponWrapper>

        <DiscountsCouponWrapper isDiscount={!!promoCode}>
          <TextCoupon>Discounts</TextCoupon>
          <TextCoupon2>{`-$${discountValue}`}</TextCoupon2>
        </DiscountsCouponWrapper>

        <OrderCouponWrapper>
          <TextCouponTitleTotal>{'Order Total:'}</TextCouponTitleTotal>
          <TextCouponTotal>{`$${totalAmount}`}</TextCouponTotal>
        </OrderCouponWrapper>

        <CouponWrapper>
          <TextCoupon>Initial Payment</TextCoupon>
          <TextCoupon2>{`$${initialAmount}`}</TextCoupon2>
        </CouponWrapper>

        <CouponWrapper>
          <TextCoupon>+11 Payments Of</TextCoupon>
          <TextCoupon2>{`$${perMonth}`}</TextCoupon2>
        </CouponWrapper>
      </SuccessCouponContainer>
      {isFirstMonthDiscount && (
        <StyledCheckbox
          variant={CheckboxVariant.white}
          label={amountFirstDiscountType ? checkboxLabelForDiscountAmount : checkboxLabelForDiscountPercentage}
          onClick={toggleIsFirstMonthDiscount}
          checked={isFirstMonthDiscount}
        />
      )}
    </div>
  );
};

export default SuccessCoupon;

const SuccessCouponContainer = styled.div`
  width: 300px;
  display: grid;
  gap: 7px;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
`;

const TextCoupon = styled.span`
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_medium_14}
  `};
`;

const TextCouponTitleTotal = styled.span`
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_bold_14}
  `};
`;

const TextCoupon2 = styled.span`
  margin-left: auto;
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_medium_14}
  `};
`;

const TextCouponTotal = styled.span`
  margin-left: auto;
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_bold_14}
  `};
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ theme: { typography, colors } }) => css`
    margin-top: 20px;
    margin-bottom: 20px;

    ${typography.body_basic_bold_14}

    & > span {
      color: ${colors.neutrals[11]};
    }
  `}
`;

const CouponWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

const DiscountsCouponWrapper = styled.div<{ isDiscount: boolean }>`
  display: flex;
  gap: 40px;
  color: ${({ isDiscount }) => (isDiscount ? '#55b47d' : '#9B9B9B')};
`;

const OrderCouponWrapper = styled.div`
  display: flex;
  gap: 40px;
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_bold_14}
  `};
`;
