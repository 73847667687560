import { ManualTypeEnum } from 'api/generated';
import { Loader, UpgradeSubscriptionModal } from 'components';
import { accessLevels } from 'constant';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications, useToggle } from 'hooks';
import MainLayout from 'layouts/MainLayout';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

import { FlashcardContent, Header, OverallContent, TestContent } from './components';
import { SelectedMenu } from './components/Header/Header';

function Performance() {
  const { isOpen, close, open } = useToggle();
  const { errorToast } = useNotifications();
  const [selectedMenu, setSelectedMenu] = useState(SelectedMenu.OVERALL);
  const navigate = useNavigate();

  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const isPauseCertificate = useAppSelector(selectors.courses.selectIsPauseCertificate);
  const [getStatisticByTestsAction, isTestStatisticsFetching] = useAsyncAction(actions.performance.getStatisticByTests);
  const [getStatisticByFlashcardsAction, isFlashcardsStatisticLoading] = useAsyncAction(
    actions.performance.getStatisticByFlashcards,
  );

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const { hasPerformance } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;
  const [isContentLoading, setIsContentLoading] = useState(false);

  const contentMapping = useMemo(
    () => ({
      [SelectedMenu.OVERALL]: (
        <OverallContent
          isFlashcardsStatisticLoading={isFlashcardsStatisticLoading}
          isTestStatisticsFetching={isTestStatisticsFetching}
        />
      ),
      [SelectedMenu.TESTS]: <TestContent />,
      [SelectedMenu.FLASHCARDS]: <FlashcardContent />,
    }),
    [selectedMenu],
  );

  const getStatisticByTests = async () => {
    if (isPause || isPauseCertificate) {
      return;
    }
    try {
      await getStatisticByTestsAction();
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const getStatisticByFlashcards = async () => {
    if (isPause || isPauseCertificate) {
      return;
    }
    try {
      await getStatisticByFlashcardsAction();
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const checkAccess = () => {
    if (activeCourse && !hasPerformance) {
      navigate(routes.home);
      errorToast("You don't have access to performance");
    }
  };

  useEffect(() => {
    if (!isPause && !isPauseCertificate) {
      getStatisticByTests();
      getStatisticByFlashcards();
    }
    checkAccess();
  }, []);

  const { colors } = useTheme();

  if (!hasPerformance) {
    return <StyledMainLayout />;
  }

  return (
    <StyledMainLayout>
      <Header
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        setIsLoading={setIsContentLoading}
        setIsShowUpgradeModal={open}
      />
      {isContentLoading ? (
        <LoaderContainer>
          <Loader color={colors.primary[1]} secondaryColor="transparent" strokeWidth={4} />
        </LoaderContainer>
      ) : (
        contentMapping[selectedMenu]
      )}
      <UpgradeSubscriptionModal isOpen={isOpen} onClose={close} manualType={ManualTypeEnum.HomepageUpgrade} />
    </StyledMainLayout>
  );
}

export default Performance;

const StyledMainLayout = styled(MainLayout)`
  padding: 28px 32px 40px 0;
  max-width: 1224px;
  display: grid;
  grid-template-rows: 110px 1fr;
  gap: 28px;
  overflow-x: hidden;

  ${respondToHeight.lg`
    padding-block: 10px;
    gap:10px;
  `}

  ${respondToWidth.sm`
    padding:16px 12px 16px 16px;
    grid-template-rows: auto 1fr;
    gap:16px;
  `}
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
