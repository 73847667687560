import gift from 'assets/json/gift_white_3d.json';
import Lottie from 'lottie-react';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { dayjs } from 'services';
import styled from 'styled-components';

interface BonusSectionProps {
  setIsShowBonusModal: Dispatch<SetStateAction<boolean>>;
  isProduct: boolean;
}

export const BonusSection: FC<BonusSectionProps> = ({ setIsShowBonusModal, isProduct }) => {
  const today = dayjs();
  const day = today.date();

  const getOrdinal = (day: number) => {
    if (day % 10 === 1 && day % 100 !== 11) return 'st';
    if (day % 10 === 2 && day % 100 !== 12) return 'nd';
    if (day % 10 === 3 && day % 100 !== 13) return 'rd';
    return 'th';
  };
  return (
    <>
      <BonusContainer isProduct={isProduct} onClick={() => setIsShowBonusModal(true)}>
        <Lottie animationData={gift} />
        <div>
          <BonusTitle>Click to see MVP bonuses</BonusTitle>
          <BonusTitle style={{ minWidth: '70px', maxWidth: '115px' }}>{`for ${today.format('MMMM ')}${day}${getOrdinal(
            day,
          )} `}</BonusTitle>
        </div>
      </BonusContainer>
    </>
  );
};

const BonusContainer = styled.div<{ isProduct: boolean }>`
  width: 250px;
  display: flex;
  margin: ${({ isProduct }) => (isProduct ? 'unset' : '20px 0')};
  justify-content: center;
  align-items: center;
  top: ${({ isProduct }) => (isProduct ? '550px' : 'unset')};
  left: ${({ isProduct }) => (isProduct ? '35px' : 'unset')};
  position: ${({ isProduct }) => (isProduct ? 'absolute' : 'unset')};
  cursor: pointer;
`;

const BonusTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  border-bottom: 1px dashed ${({ theme: { colors } }) => colors.primary[1]};
`;
