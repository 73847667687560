import { Button, Input, PasswordInput, ScrollbarContainer, StyledText } from 'UI';
import { AddFreeAccessDtoBillingTypeEnum } from 'api/generated';
import { Buffer } from 'buffer';
import { regexp } from 'constant/regexp';
import { routes } from 'constant/routes';
import { useAsyncAction, useNotifications, useQuery } from 'hooks';
import { Header } from 'pages/SignIn/components';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';

type FormType = {
  email: string;
  password: string;
  billingType: AddFreeAccessDtoBillingTypeEnum;
};

function Invite() {
  const query = useQuery();
  const navigate = useNavigate();
  const { errorToast } = useNotifications();
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(selectors.auth.selectAccessToken);
  const user = useAppSelector(selectors.user.selectUser);

  const code = query.get('code') || '';

  const [signUpPromoAction, isLoading] = useAsyncAction(actions.auth.signUpPromo);
  const [getCourseByIdAction] = useAsyncAction(actions.courses.getCourseById);

  const { control, handleSubmit } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: { email: '', password: '' },
  });

  const getInviteCourseById = async (courseId: string | null) => {
    if (!courseId) return;
    try {
      return await getCourseByIdAction(courseId);
    } catch (error) {
      errorToast('Course not found!');
    }
  };

  const signUpPromo = async (
    email: string,
    password: string,
    code: string,
    billingType: AddFreeAccessDtoBillingTypeEnum,
    authEmail?: string,
  ) => {
    try {
      await signUpPromoAction({ email, password, code, billingType });
      navigate(routes.home);
      return 200;
    } catch (error: any) {
      // case: if user already registered by url invite code
      if (isAuth) {
        if (authEmail === email) {
          navigate(routes.home);
          return 200;
        } else {
          dispatch(actions.auth.signOut());
          errorToast('Account not to matched the url of invite!');
        }
      } else {
        errorToast(error.message);
      }
    }
  };

  const onSubmit = async (data: FormType) => {
    try {
      if (code) {
        const decodeHash = JSON.parse(Buffer.from(code, 'base64').toString());
        const res = await signUpPromo(data.email, data.password, code, data.billingType || 'gold', user?.email);
        if (res === 200) {
          getInviteCourseById(decodeHash.courseId).then((res: any) => {
            dispatch(actions.courses.inviteCourse(res));
          });
        }
      }
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  return (
    <Container>
      <Header />
      <Main>
        <Wrapper>
          <Title>Sign in</Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: true,
                  pattern: {
                    value: regexp.emailRegExp,
                    message: 'Incorrect email',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <StyledInput
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value.trim())}
                    label="Email"
                    error={error?.message}
                    maxLength={155}
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <PasswordInput
                    iconButtonType="button"
                    value={field.value}
                    onChange={field.onChange}
                    label="Password"
                  />
                )}
              />
            </div>
            <StyledButton variant="primary" type="submit" isLoading={isLoading}>
              Sign in
            </StyledButton>
          </Form>
        </Wrapper>
      </Main>
    </Container>
  );
}

export default Invite;

const Container = styled(ScrollbarContainer)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};
  display: grid;
  grid-template-rows: 60px 1fr;

  ${respondToWidth.s`
    grid-template-rows: 47px 1fr;
  `}
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: grid;
  place-items: center;
  padding-top: 48px;
  padding-bottom: 84px;

  ${respondToWidth.s`
    padding:0;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 668px;
  height: 100%;
  max-height: 708px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 60px 100px;
  margin: 20px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 28px;
  text-align: center;

  ${respondToWidth.sm`
    padding: 30px;
    height:100%;
    max-height:100%;
    flex-grow:0;
    justify-content: center;
    border-radius:0;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 0 16px;
    justify-content: start;
  `}
`;

const Title = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.primary[1]};
  ${({ theme: { typography } }) => typography.title_2_bold_32};
  margin-bottom: 120px;

  ${respondToWidth.s`
    margin-bottom: 40px;
  `}
  ${respondToHeight.sm`
    margin-bottom: 60px';
  `}

${respondToHeight.s`
    margin-bottom:20px';
  `}
`;

const Form = styled.form`
  height: calc(100vh - 530px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 270px;
  ${respondToWidth.sm`
    height: auto;
  `}
`;

const StyledInput = styled(Input)`
  margin-bottom: 44px;

  ${respondToWidth.s`
    margin-bottom: 16px;
  `}
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 52px;
  ${respondToWidth.s`
    margin-top: 52px;
  `}
`;
