import { useAsyncAction, useNotifications, useToggle } from 'hooks';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Oval as Loader } from 'react-loader-spinner';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import {
  AnswerEnum,
  FlashcardsStatisticValuesType,
  SplitType,
  StatisticsArrayType,
  StatisticsVariants,
  TestProgressType,
} from 'store/ducks/performance/types';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { getUpFirstLetter } from 'utils';

import StatisticModal from '../StatisticModal';
import CircleChart from './components/CircleChart';

enum SelectedTestTag {
  BY_CHAPTER = 'By chapters',
  BY_DOMAINS = 'By domains',
}

type TagVariant = {
  value: SelectedTestTag;
  text: string;
};

// todo: replace enum from api (27.07.2023 - does not exist)
enum TestsStatisticSplit {
  ByChapters,
  ByDomains,
}

const tagNameValueAdapter: Record<SelectedTestTag, TestsStatisticSplit> = {
  [SelectedTestTag.BY_DOMAINS]: TestsStatisticSplit.ByDomains,
  [SelectedTestTag.BY_CHAPTER]: TestsStatisticSplit.ByChapters,
};

type OverallContentProps = {
  isTestStatisticsFetching: boolean;
  isFlashcardsStatisticLoading: boolean;
};
const OverallContent = ({ isTestStatisticsFetching, isFlashcardsStatisticLoading }: OverallContentProps) => {
  const { colors } = useTheme();
  const { errorToast } = useNotifications();
  const dispatch = useAppDispatch();

  const [isInactiveAll, setIsInactiveAll] = useState(false);
  const [selectedTag, setSelectedTag] = useState<TestsStatisticSplit>(TestsStatisticSplit.ByChapters);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const isPauseCertificate = useAppSelector(selectors.courses.selectIsPauseCertificate);

  const [statisticsVariant, setStatisticsVariant] = useState<StatisticsVariants | null>(null);
  const [answerType, setAnswerType] = useState<AnswerEnum | null>(null);

  const { isOpen: isOpenStatisticsModal, close: closeStatisticsModal, open } = useToggle();

  const modalTitle = getUpFirstLetter(answerType || '');

  const [getTestStatisticByAnswerAction, isTestStatisticsByAnswerFetching] = useAsyncAction(
    actions.performance.getTestStatisticByAnswer,
  );
  const [getFlashcardsStatisticByAnswerAction, isFlashcardsStatisticsByAnswerLoading] = useAsyncAction(
    actions.performance.getFlashcardsStatisticByAnswer,
  );

  const testsStatistics = useAppSelector(selectors.performance.selectTestsStatistics);
  const testsStatisticsByAnswer = useAppSelector(selectors.performance.selectTestsStatisticsByAnswer);
  const testsStatisticsByAnswerMeta = useAppSelector(selectors.performance.selectTestsStatisticsByAnswerMeta);
  const flashcardsStatistics = useAppSelector(selectors.performance.selectFlashcardsStatistics);
  const flashcardsStatisticsByAnswer = useAppSelector(selectors.performance.selectFlashcardsStatisticsByAnswer);
  const flashcardsStatisticsByAnswerMeta = useAppSelector(selectors.performance.selectFlashcardsStatisticsByAnswerMeta);
  const course = useAppSelector((store) => store.courses.activeCourse);

  const [tagNames, setTagNames] = useState<TagVariant[]>([
    {
      value: SelectedTestTag.BY_CHAPTER,
      text: SelectedTestTag.BY_CHAPTER,
    },
    {
      value: SelectedTestTag.BY_DOMAINS,
      text: SelectedTestTag.BY_DOMAINS,
    },
  ]);

  const testsAnswerType = statisticsVariant === StatisticsVariants.TESTS ? answerType : null;
  const flashcardsAnswerType = statisticsVariant === StatisticsVariants.FLASHCARDS ? answerType : null;

  const isStatisticsByAnswerLoading = testsAnswerType
    ? isTestStatisticsByAnswerFetching
    : isFlashcardsStatisticsByAnswerLoading;
  const statisticsByAnswer = testsAnswerType ? testsStatisticsByAnswer : flashcardsStatisticsByAnswer;
  const statisticsByAnswerMeta = testsAnswerType ? testsStatisticsByAnswerMeta : flashcardsStatisticsByAnswerMeta;

  const isColorReverse =
    answerType === AnswerEnum.INCORRECT ||
    answerType === AnswerEnum.UNUSED ||
    answerType === AnswerEnum.NO ||
    answerType === AnswerEnum.KINDA;

  const splitMapping = { [TestsStatisticSplit.ByChapters]: 'chapter', [TestsStatisticSplit.ByDomains]: 'domain' };

  const testTags = tagNames.map((tagName) => ({
    text: tagName.text,
    onSelect: () => setSelectedTag(tagNameValueAdapter[tagName.value]),
    isSelected: tagNameValueAdapter[tagName.value] === selectedTag,
  }));

  const openStatisticsModal = (type: StatisticsVariants, answerType: AnswerEnum) => {
    setStatisticsVariant(type);
    setAnswerType(answerType);
    open();
  };

  const getStatisticByAnswer = async (page = 1, limit?: 20) => {
    if (isOpenStatisticsModal && !isPause && !isPauseCertificate) {
      if (testsAnswerType) {
        await getTestStatisticByAnswer(page, limit);
      } else if (flashcardsAnswerType) {
        await getFlashcardsStatisticByAnswer(page, limit);
      }
    }
  };

  const getTestStatisticByAnswer = async (page = 1, limit?: number) => {
    try {
      await getTestStatisticByAnswerAction({
        split: splitMapping[selectedTag] as SplitType,
        type: answerType as TestProgressType,
        page,
        limit,
      });
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const answerMapping = {
    [AnswerEnum.NO]: 'NO',
    [AnswerEnum.KINDA]: 'KINDA',
    [AnswerEnum.YES]: 'YES',
    [AnswerEnum.UNUSED]: 'UNANSWERED',
    [AnswerEnum.CORRECT]: 'UNANSWERED',
    [AnswerEnum.INCORRECT]: 'UNANSWERED',
    [AnswerEnum.INACTIVE]: 'UNANSWERED',
  };

  const getFlashcardsStatisticByAnswer = async (page = 1, limit?: number) => {
    if (answerType) {
      try {
        await getFlashcardsStatisticByAnswerAction({
          type: answerMapping[answerType] as FlashcardsStatisticValuesType,
          page,
          limit,
        });
      } catch (error) {
        errorToast('Something went wrong');
      }
    }
  };

  useEffect(() => {
    getStatisticByAnswer();
  }, [isOpenStatisticsModal, answerType, statisticsVariant]);

  const resetTestId = () => {
    dispatch(actions.performance.setCurrentTestId(null));
  };

  useEffect(resetTestId, []);

  useEffect(() => {
    dispatch(actions.performance.clearStatisticsByAnswer());
  }, [selectedTag, statisticsVariant]);

  const isFlashcard = statisticsVariant === StatisticsVariants.FLASHCARDS;

  const subtitlePathMapping = useMemo(
    () => ({
      [AnswerEnum.CORRECT]: 'a few correct answers',
      [AnswerEnum.INCORRECT]: 'many wrong answers',
      [AnswerEnum.UNUSED]: isFlashcard ? 'many unused cards' : 'many unused questions',
      [AnswerEnum.NO]: 'many wrong answers',
      [AnswerEnum.KINDA]: 'many wrong answers',
      [AnswerEnum.YES]: 'a few correct answers',
      [AnswerEnum.INACTIVE]: '',
    }),
    [isFlashcard],
  );

  const testsStatisticsArray: StatisticsArrayType[] = [
    {
      name: AnswerEnum.CORRECT,
      count: testsStatistics?.correct.count || 0,
      percent: testsStatistics?.correct.percent || 0,
    },
    {
      name: AnswerEnum.INCORRECT,
      count: testsStatistics?.incorrect.count || 0,
      percent: testsStatistics?.incorrect.percent || 0,
    },
    {
      name: AnswerEnum.UNUSED,
      count: testsStatistics?.unused.count || 0,
      percent: testsStatistics?.unused.percent || 0,
    },
  ];

  const flashcardsStatisticsArray: StatisticsArrayType[] = [
    {
      name: AnswerEnum.YES,
      count: flashcardsStatistics?.yes.count || 0,
      percent: flashcardsStatistics?.yes.percent || 0,
    },
    {
      name: AnswerEnum.KINDA,
      count: flashcardsStatistics?.kinda.count || 0,
      percent: flashcardsStatistics?.kinda.percent || 0,
    },
    {
      name: AnswerEnum.NO,
      count: flashcardsStatistics?.no.count || 0,
      percent: flashcardsStatistics?.no.percent || 0,
    },
    {
      name: AnswerEnum.UNUSED,
      count: flashcardsStatistics?.unanswered.count || 0,
      percent: flashcardsStatistics?.unanswered.percent || 0,
    },
  ];

  useLayoutEffect(() => {
    if (course?.domainStatsButtonName && course?.chapterStatsButtonName) {
      setTagNames([
        {
          value: SelectedTestTag.BY_CHAPTER,
          text: course?.chapterStatsButtonName,
        },
        {
          value: SelectedTestTag.BY_DOMAINS,
          text: course?.domainStatsButtonName,
        },
      ]);
    }
  }, [course?.domainStatsButtonName, course?.chapterStatsButtonName]);

  if (isTestStatisticsFetching && isFlashcardsStatisticLoading) {
    return (
      <LoaderContainer>
        <Loader color={colors.primary[1]} secondaryColor="transparent" strokeWidth={4} />
      </LoaderContainer>
    );
  }

  return (
    <MainContainer>
      <CircleChart
        isOpenStatisticsModal={isOpenStatisticsModal}
        answerType={testsAnswerType}
        setAnswerType={setAnswerType}
        openStatisticsModal={openStatisticsModal}
        data={testsStatisticsArray}
        total={testsStatistics?.total || 0}
        type={StatisticsVariants.TESTS}
        title="Tests"
        isInactiveAll={isInactiveAll}
        setIsInactiveAll={setIsInactiveAll}
        tags={testTags}
      />
      <CircleChart
        answerType={flashcardsAnswerType}
        setAnswerType={setAnswerType}
        isOpenStatisticsModal={isOpenStatisticsModal}
        openStatisticsModal={openStatisticsModal}
        data={flashcardsStatisticsArray}
        total={flashcardsStatistics?.total || 0}
        type={StatisticsVariants.FLASHCARDS}
        title="Flashcards"
        isInactiveAll={isInactiveAll}
        setIsInactiveAll={setIsInactiveAll}
      />
      {isOpenStatisticsModal && (
        <StatisticModal
          isOpen={isOpenStatisticsModal}
          onClose={closeStatisticsModal}
          data={statisticsByAnswer}
          title={modalTitle}
          isColorReverse={isColorReverse}
          isLeftPosition={isFlashcard}
          isLoading={isStatisticsByAnswerLoading}
          meta={statisticsByAnswerMeta}
          getMoreStatistics={getStatisticByAnswer}
          subtitlePath={answerType && subtitlePathMapping[answerType]}
        />
      )}
    </MainContainer>
  );
};

export default OverallContent;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div`
  position: relative;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  ${respondToWidth.ls`
    flex-direction: column;
    gap:16px;
  `}
`;
