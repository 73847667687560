import { Button, IconButton, StyledText } from 'UI';
import SuccessModal from 'UI/Modals/SuccessModal';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { ManualTypeEnum, PaymentDtoLevelEnum, PaymentDtoTypeEnum, SubscribeDtoTypeEnum } from 'api/generated';
import { CaretIcon, UserIcon } from 'assets/icons';
import BonusBackground from 'assets/images/bonus-background.png';
import { StreakModal, UpgradeSubscriptionModal } from 'components';
import { SubscriptionType } from 'components/Sidebar/Sidebar';
import StudyThisButton from 'components/StydyThisButton';
import { SubscribeType, accessLevels } from 'constant';
import { routes } from 'constant/routes';
import { useAsyncAction, useGoogleAnalytics, useNotifications, useQuery } from 'hooks';
import MainLayout from 'layouts/MainLayout';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StorageKeys } from 'services';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { CourseOptionsForPayment } from 'store/ducks/courses/types';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { NoStreakModal, StreakFireButton } from '../../components';
import { CalendarButton, StudyPlan, SuggestionButton } from './components';
import Bonuses from './components/Bonuses';
import InstructionManualModal from './components/InstructionManualModal';
import Performance from './components/Performance';
import PerformancePlug from './components/PerformancePlug';
import Progress from './components/Progress';

type HomeProps = {
  subscription?: SubscriptionType;
};

const Home: FC<HomeProps> = () => {
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const { errorToast } = useNotifications();
  const { getEventSubscriptionPaymentModalOpenedGA } = useGoogleAnalytics();
  const query = useQuery();
  const navigate = useNavigate();

  const planRef = useRef<HTMLDivElement>(null);
  const [isShowUpgradeModal, setIsShowUpgradeModal] = useState(false);
  const [isInstructionShowModal, setIsShowInstructionModal] = useState(false);
  const [isShowInviteModal, setIsShowInviteModal] = useState(false);
  const [isShowStreakModal, setIsShowStreakModal] = useState(false);
  const isFirstLogin = useAppSelector((store) => store.user.user?.isFirstLogin);
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const inviteCourse = useAppSelector(selectors.courses.inviteCourse);
  const inviteCourseId = useAppSelector(selectors.settings.inviteCourseId);
  const fullName = useAppSelector((store) => store.user.user?.name);
  const streakCount = useAppSelector((store) => store.performance.courseStatistic?.streak);
  const hasNoStreak = !Number(streakCount);
  const paymentLevel = useAppSelector(selectors.courses.selectCurrentPaymentLevel);
  const paymentType = useAppSelector(selectors.courses.selectCurrentPaymentType);
  const isGoldPaymentOrTrial = paymentLevel === PaymentDtoLevelEnum.Gold || paymentType === PaymentDtoTypeEnum.Free;
  const isTrial = paymentType === PaymentDtoTypeEnum.Free;

  const [getCourseByIdAction] = useAsyncAction(actions.courses.getCourseById);
  const [getCustomerSettings] = useAsyncAction(actions.settings.getCustomerSettings);

  const redirectStatus = query.get('redirect_status');
  const courseId = query.get(StorageKeys.COURSE_ID);

  const subscribeType = query.get(StorageKeys.SUBSCRIBE_TYPE) as SubscribeDtoTypeEnum;
  const term = query.get(StorageKeys.TERM_PERIOD) as TermEnum;

  const isShowPaymentModal = courseId && subscribeType && term;
  const isStripeStatusSucceeded = redirectStatus === 'succeeded';

  const selectCourseOptionsForPayment = (options: CourseOptionsForPayment) =>
    dispatch(actions.courses.selectCourseOptionsForPayment(options));

  const openPaymentModalOpen = () => {
    getEventSubscriptionPaymentModalOpenedGA();
    dispatch(actions.courses.openPaymentModalOpen());
  };

  const openChooseProductModalOpen = () => {
    dispatch(actions.courses.openChooseProductModalOpen());
  };

  const handleCloseStreakModal = () => {
    setIsShowStreakModal(false);
  };

  const handleOpenStreakModal = () => {
    setIsShowStreakModal(true);
  };

  const handleOpenProfilePage = () => {
    navigate(routes.profile);
  };

  const handleShowInviteModalResolver = () => {
    setIsShowInviteModal(true);
  };

  const getCourseById = async (courseId: string) => {
    try {
      const course = await getCourseByIdAction(courseId);

      const prices = {
        [TermEnum.MONTHLY]: {
          [SubscribeDtoTypeEnum.Bronze]: course.prices.monthlySubscriptions?.bronze,
          [SubscribeDtoTypeEnum.Silver]: course.prices.monthlySubscriptions?.silver,
          [SubscribeDtoTypeEnum.Gold]: course.prices.monthlySubscriptions?.gold,
        },
        [TermEnum.YEARLY]: {
          [SubscribeDtoTypeEnum.Bronze]: course.prices.annualSubscriptions?.bronze,
          [SubscribeDtoTypeEnum.Silver]: course.prices.annualSubscriptions?.silver,
          [SubscribeDtoTypeEnum.Gold]: course.prices.annualSubscriptions?.gold,
        },
        [TermEnum.LIFE_TIME]: {
          [SubscribeDtoTypeEnum.Bronze]: course.prices.purchase,
          [SubscribeDtoTypeEnum.Silver]: course.prices.purchase,
          [SubscribeDtoTypeEnum.Gold]: course.prices.purchase,
        },
      };

      const { amount = 0, oldAmount } = prices[term][subscribeType as SubscribeType] || {};
      const hasSubscription =
        course.currentPayment.type === 'Subscription' && course.currentPayment.status === 'active';

      selectCourseOptionsForPayment({
        courseId,
        subscribeType,
        term,
        type: course.type,
        amount,
        oldAmount,
        isUpdate: hasSubscription,
      });
      openPaymentModalOpen();
    } catch {
      errorToast('Something went wrong');
    }
  };

  const scrollToPlan = () => {
    planRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleCloseInviteModal = () => {
    dispatch(actions.courses.inviteCourse(null));
    setIsShowInviteModal(false);
  };

  useEffect(() => {
    setIsShowInstructionModal(isStripeStatusSucceeded);
  }, [isStripeStatusSucceeded]);

  useEffect(() => {
    if (isShowPaymentModal) {
      getCourseById(courseId);
    }
  }, [isShowPaymentModal]);

  useEffect(() => {
    if (inviteCourse?.id && inviteCourse.id !== inviteCourseId) {
      dispatch(actions.settings.setInviteCourseId(inviteCourse.id));
      handleShowInviteModalResolver();
    }
  }, [inviteCourse?.id]);

  useEffect(() => {
    getCustomerSettings();
  }, []);

  if (!activeCourse) {
    return <StyledMainLayout />;
  }

  const { hasPerformance } = activeCourse.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  return (
    <StyledMainLayout>
      <MainContainer>
        <HeaderWrapper>
          <TitleWrapper>
            <Title>
              {isFirstLogin ? 'Hello' : 'Welcome back'}
              <Transfer>{'\n'}</Transfer>
              {fullName && `, ${fullName}`}
            </Title>
            <SuggestionButton />
          </TitleWrapper>

          <RightSideWrapper>
            <Additionals>
              {isTrial && (
                <Button variant="primary" size="small" onClick={openChooseProductModalOpen}>
                  Upgrade
                </Button>
              )}
              {isGoldPaymentOrTrial && <StreakFireButton onClick={handleOpenStreakModal} />}
              <StudyThisButton />
            </Additionals>
            <CalendarButton />

            <StyledIconButton iconComponent={<UserIcon />} onClick={handleOpenProfilePage} />
          </RightSideWrapper>
        </HeaderWrapper>
        <Container>
          <StudyPlan ref={planRef} openInstructionModal={() => setIsShowInstructionModal(true)} />
          <Cover $hasPerformance={hasPerformance}>
            {hasPerformance && (
              <BonusWrapper>
                <StyledCheatSheet />
              </BonusWrapper>
            )}

            {hasPerformance ? (
              <>
                <Wrapper>
                  <Progress />
                  <Performance />
                </Wrapper>
              </>
            ) : (
              <>
                <BonusWithoutPerformanceWrapper>
                  <StyledCheatSheet />
                </BonusWithoutPerformanceWrapper>
                <PerformancePlug onClick={openChooseProductModalOpen} />
              </>
            )}
          </Cover>
          <ButtonContainer onClick={scrollToPlan}>
            <StyledCaret color={colors.primary[1]} size={24} />
            <Text>Go to plan</Text>
          </ButtonContainer>
        </Container>
      </MainContainer>
      <InstructionManualModal isOpen={isInstructionShowModal} onClose={() => setIsShowInstructionModal(false)} />
      <UpgradeSubscriptionModal
        isOpen={isShowUpgradeModal}
        onClose={() => setIsShowUpgradeModal(false)}
        manualType={ManualTypeEnum.HomepageUpgrade}
      />
      <SuccessModal
        isOpen={isShowInviteModal}
        onClose={handleCloseInviteModal}
        title="Congratulations"
        text={`You have been invited to the course ${inviteCourse?.name}`}
      />

      {/** This condition is necessary to prevent early rendering of the component */}
      {isShowStreakModal && hasNoStreak ? (
        <NoStreakModal isModalVisible={isShowStreakModal} setModalVisible={handleCloseStreakModal} />
      ) : (
        <StreakModal isOpen={isShowStreakModal} onClose={handleCloseStreakModal} />
      )}

      <InstructionButtonContainer>
        <StyledButton variant="primary" onClick={() => setIsShowInstructionModal(true)}>
          Instruction manual
        </StyledButton>
      </InstructionButtonContainer>
    </StyledMainLayout>
  );
};

export default Home;

const StyledMainLayout = styled(MainLayout)`
  max-width: 1264px;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  position: relative;

  ${respondToWidth.lg`
    padding-right: 32px;
  `}

  ${respondToWidth.sm`
    padding:0;
  `}
`;

export const MainContainer = styled.div`
  width: 100%;

  ${respondToWidth.sm`
   padding: 20px 16px 0 16px;
   margin-top: 50px;
  `}
`;

export const Title = styled(StyledText)`
  ${({ theme: { typography } }) => css`
    ${typography.title_2_bold_32}

    ${respondToWidth.sm`
      ${typography.title_4_bold_24}
    `}
  `};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  ${respondToWidth.sm`
    flex-direction: column;
  `};
`;

const Transfer = styled.span`
  margin: 0;
  padding: 0;
  display: none;

  ${respondToWidth.sm`
    display: block
  `};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  gap: 24px;
  padding-bottom: 40px;

  ${respondToWidth.ls`
    position: relative;
    flex-direction:column;
    width: 100%;
  `}

  ${respondToWidth.sm`
    position: relative;
    flex-direction:column-reverse;
    gap:0;
    padding-bottom: 0;
  `}
`;

const Cover = styled.div<{ $hasPerformance: boolean }>`
  width: 100%;
  max-height: 748px;
  min-height: 748px;
  flex-grow: 1;
  display: ${({ $hasPerformance }) => ($hasPerformance ? 'grid' : 'block')};
  grid-template-rows: 160px 456px 84px;
  gap: 24px;

  ${respondToWidth.lg`
  grid-template-rows: 160px 936px 84px;
  max-height: 100%;
  `}

  ${({ $hasPerformance }) => respondToWidth.sm`
    grid-template-rows: 138px 1fr 144px;
    gap:16px;
    min-height: ${!$hasPerformance && '100%'};
  `}
`;

const BonusWithoutPerformanceWrapper = styled.div<{ height?: number }>`
  height: 33%;
  width: 100%;
  background-image: url(${BonusBackground});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1380px) {
    height: 30%;
  }

  @media (max-width: 1280px) {
    height: 27%;
  }

  @media (max-width: 1180px) {
    height: 22%;
  }
  @media (max-width: 1080px) {
    height: 18%;
  }

  @media (max-width: 1023px) {
    height: 246px;
  }

  @media (max-width: 923px) {
    height: 220px;
  }
  @media (max-width: 832px) {
    height: 185px;
  }
  @media (max-width: 743px) {
    height: 220px;
  }

  @media (max-width: 623px) {
    height: 180px;
  }

  @media (max-width: 523px) {
    height: 150px;
  }
  @media (max-width: 423px) {
    height: 130px;
  }
`;

const BonusWrapper = styled.div<{ height?: number }>`
  height: 154%;
  width: 100%;
  background-image: url(${BonusBackground});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1380px) {
    height: 130%;
  }

  @media (max-width: 1280px) {
    height: 120%;
  }
  @media (max-width: 1180px) {
    height: 100%;
  }
  @media (max-width: 1080px) {
    height: 80%;
  }
  @media (max-width: 1023px) {
    height: 150%;
  }

  @media (max-width: 923px) {
    height: 130%;
  }
  @media (max-width: 832px) {
    height: 110%;
  }
  @media (max-width: 723px) {
    height: 150%;
  }
  @media (max-width: 623px) {
    height: 130%;
  }
  @media (max-width: 523px) {
    height: 110%;
  }
  @media (max-width: 423px) {
    height: 90%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: 90px;

  ${respondToWidth.lg`
    grid-template-columns: 1fr;
    grid-template-rows: 456px 456px;
  `}

  ${respondToWidth.sm`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap:16px;
  `}

  @media (max-width: 1380px) {
    margin-top: 60px;
  }

  @media (max-width: 1250px) {
    margin-top: 5px;
  }

  @media (max-width: 1150px) {
    margin-top: -15px;
  }

  @media (max-width: 1070px) {
    margin-top: -30px;
  }

  @media (max-width: 1023px) {
    margin-top: 70px;
  }

  @media (max-width: 900px) {
    margin-top: 30px;
  }

  @media (max-width: 770px) {
    margin-top: 10px;
  }

  @media (max-width: 725px) {
    margin-top: 65px;
  }

  @media (max-width: 620px) {
    margin-top: 35px;
  }

  @media (max-width: 490px) {
    margin-top: 5px;
  }

  @media (max-width: 420px) {
    margin-top: -30px;
  }
`;

const ButtonContainer = styled.button`
  width: 100%;
  border-radius: 12px;
  padding-block: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border: none;
  outline: none;
  cursor: pointer;

  ${respondToWidth.sm`
    display: flex;
    margin-bottom: 16px;
  `}
`;

const Text = styled(StyledText)`
  margin-left: 6px;
  ${({ theme: { typography } }) => typography.body_basic_bold_14}
`;

const StyledCaret = styled(CaretIcon)`
  transform: rotate(90deg);
`;

const InstructionButtonContainer = styled.div`
  display: none;
  width: 100%;
  height: 84px;
  padding: 16px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.neutrals[9]};
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  border-radius: 0;
  position: fixed;
  z-index: 2;
  bottom: 0;
  ${respondToWidth.sm`
    display: block;
  `}
`;

const StyledButton = styled(Button)`
  padding-inline: 0;
  text-transform: capitalize;
`;

const StyledCheatSheet = styled(Bonuses)`
  ${respondToWidth.sm`
  margin-bottom: 16px;
  `}
`;

const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  ${respondToWidth.ls`
    position: relative;
    flex-direction: column-reverse;
  `}
`;

const Additionals = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledIconButton = styled(IconButton)`
  max-height: 36px;

  ${respondToWidth.ls`
  position: absolute;
  left: -50px;
  `}

  ${respondToWidth.sm`
  display: none;
  `}
`;
