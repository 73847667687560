export enum StorageKeys {
  MAIN_COLOR = 'mainColor',
  COURSE_ID = 'courseId',
  SUBSCRIBE_TYPE = 'subscribeType',
  TERM_PERIOD = 'TermPeriod',
}

class StorageService {
  getItem(item: StorageKeys) {
    return localStorage.getItem(item);
  }

  setItem(item: StorageKeys, token: string) {
    localStorage.setItem(item, token);
  }

  resetItem(item: StorageKeys) {
    localStorage.removeItem(item);
  }
}

export default new StorageService();
