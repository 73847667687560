import { ManualTypeEnum } from 'api/generated';
import { AxiosError } from 'axios';
import { Loader, Sidebar, UpgradeSubscriptionModal } from 'components';
import { useAsyncAction, useClientSize, useNotifications, useToggle } from 'hooks';
import React, { useEffect, useState } from 'react';
import { actions, selectors, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import { Chapter, ContentModal, ContentSidebar } from './components';

const StudyGuide = () => {
  const { errorToast } = useNotifications();
  const { getIsBreakpoint } = useClientSize();
  const { isOpen, close, toggle } = useToggle(true);
  const { isOpen: isUpgradeModalOpen, close: closeUpgradeModal, open: openUpgradeModal } = useToggle();

  const [isContentLoading, setIsContentLoading] = useState(false);

  const [getGuideByCourseIdAction, isGuideFetching] = useAsyncAction(actions.studyGuide.getGuideByCourseId);
  const [getTableOfContentForGuideAction] = useAsyncAction(actions.studyGuide.getTableOfContentForGuide);
  const [getNotesAction] = useAsyncAction(actions.studyGuide.getNotes);

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const studyGuide = useAppSelector(selectors.studyGuide.selectStudyGuide);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const isPauseCertificate = useAppSelector(selectors.courses.selectIsPauseCertificate);
  const tableOfGuideContent = useAppSelector(selectors.studyGuide.selectTableOfGuideContent);

  const isWidthSm = getIsBreakpoint('sm');

  const isShowChapter =
    activeCourse && !isGuideFetching && studyGuide?.id && tableOfGuideContent?.chapters && !isContentLoading;

  useEffect(() => {
    if (activeCourse) {
      getGuideByCourseId(activeCourse.id);
    }
  }, [activeCourse?.id]);

  const getGuideByCourseId = async (courseId: string) => {
    try {
      setIsContentLoading(true);
      const { id } = await getGuideByCourseIdAction(courseId);
      await getGuideContent(id);
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    } finally {
      setIsContentLoading(false);
    }
  };

  const getGuideContent = async (guideId: string) => {
    try {
      await Promise.all([await getTableOfContentForGuideAction(guideId), await getNotesAction(guideId)]);
    } catch (error) {
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    }
  };

  const openAudioUpgradeModal = () => {
    openUpgradeModal();
  };

  return (
    <Layout>
      <StyledSidebar />
      <Wrapper>
        {isShowChapter && (
          <Chapter
            openAudioUpgradeModal={openAudioUpgradeModal}
            openUpgradeModal={openUpgradeModal}
            toggleContentModal={toggle}
            isPause={isPause || isPauseCertificate}
          />
        )}
        {!isShowChapter && <Loader />}
      </Wrapper>
      {isWidthSm ? (
        <ContentModal isLoading={isContentLoading} isOpen={isOpen} onClose={close} />
      ) : (
        <ContentSidebar
          openAudioUpgradeModal={openAudioUpgradeModal}
          isLoading={isContentLoading}
          isOpen={isOpen}
          onClose={close}
          toggle={toggle}
        />
      )}
      <UpgradeSubscriptionModal
        // title="To listen to the rest of the chapters, purchase gold subscription" //todo
        isOpen={isUpgradeModalOpen}
        onClose={closeUpgradeModal}
        manualType={ManualTypeEnum.StudyGuideUpgrade}
      />
    </Layout>
  );
};

export default StudyGuide;

const Layout = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;

  margin: 0;
  padding: 0;

  display: grid;
  place-items: center;
  grid-template-columns: auto 1fr auto;
  overflow: hidden;

  background-color: ${({ theme: { colors } }) => colors.neutrals[10]};

  ${respondToWidth.sm`
      grid-template-columns: 1fr;
      grid-gap: 0;
  `}
`;

const StyledSidebar = styled(Sidebar)`
  margin-right: 28px;

  ${respondToWidth.sm`
      margin-right: 0;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: calc(1440px - 148px);
  height: 100vh;
  position: relative;

  padding-top: 28px;

  ${respondToWidth.ls`
    padding-right: 80px;
  `};

  ${respondToWidth.sm`
      height: 100vh;
      max-height: calc(100vh - 47px);
      padding: 0;
  `};
`;
