import { Button, StyledText } from 'UI';
import { ManualTypeEnum, PaymentDtoTypeEnum } from 'api/generated';
import { UpgradeSubscriptionModal } from 'components';
import { accessLevels } from 'constant';
import { routes } from 'constant/routes';
import { useAsyncAction, useClientSize, useInfinityScroll, useNotifications, useToggle } from 'hooks';
import MainLayout from 'layouts/MainLayout';
import { useCallback, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { PaginationType } from 'types';

import { Flashcard } from './components';

const FlashcardsPacks = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errorToast } = useNotifications();
  const { colors } = useTheme();
  const { getIsBreakpoint } = useClientSize();

  const {
    isOpen: isUpgradeSubscriptionModalOpen,
    close: closeUpgradeSubscriptionModal,
    open: openUpgradeSubscriptionModal,
  } = useToggle();

  const [getPacksFlashcardsAction, isPacksLoading] = useAsyncAction(actions.flashcardsPacks.getPacksFlashcards);
  const [getMarkedForReCheckingCountAction, isMarkedForReCheckingCountLoading] = useAsyncAction(
    actions.flashcardsPacks.getMarkedForReCheckingCount,
  );

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const flashcardsPacks = useAppSelector(selectors.flashcardsPacks.selectFlashcardsPacks);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const isPauseCertificate = useAppSelector(selectors.courses.selectIsPauseCertificate);
  const flashcardsPacksMeta = useAppSelector(selectors.flashcardsPacks.selectFlashcardsPacksMeta);
  const markedForReCheckingCount = useAppSelector(selectors.flashcardsPacks.selectMarkedForReCheckingCount);
  const clearFlashcardsSlice = () => dispatch(actions.flashcards.clearSlice());
  const isInstallment = activeCourse?.currentPayment.type === PaymentDtoTypeEnum.Installment;

  const isWidthSm = getIsBreakpoint('sm');

  const { hasAllFlashcardsDecks } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const isMarkedForReCheckingCount = typeof markedForReCheckingCount === 'number';
  const isShowMarkedForReCheckingPack = !isMarkedForReCheckingCountLoading && isMarkedForReCheckingCount;

  const nextPage = (flashcardsPacksMeta?.currentPage || 0) + 1;
  const hasFlashcardsPackMore = (flashcardsPacksMeta?.currentPage || 0) < (flashcardsPacksMeta?.totalPages || 0);

  const openFlashcardsPack = useCallback((id: string) => {
    navigate({ pathname: routes.flashcards, search: `?packId=${id}` });
  }, []);

  const getPacksFlashcards = async (actionPayload?: PaginationType) => {
    try {
      await getPacksFlashcardsAction(actionPayload);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const getMarkedForReCheckingCount = async () => {
    try {
      await getMarkedForReCheckingCountAction();
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getPacksFlashcards({ page: nextPage, limit: 20 }),
    hasAfterMore: hasFlashcardsPackMore,
    isLoading: isPacksLoading,
  });

  useEffect(() => {
    clearFlashcardsSlice();
    getMarkedForReCheckingCount();
    getPacksFlashcards({ limit: 20 });
  }, []);

  const isLoading = (isMarkedForReCheckingCountLoading && isPacksLoading) || !activeCourse;

  if (isLoading) {
    return (
      <MainLayout scrollContainerRef={listRef} onScroll={hasAllFlashcardsDecks ? handleScroll : undefined}>
        <LoaderContainer>
          <ThreeDots color={colors.primary[3]} />
        </LoaderContainer>
      </MainLayout>
    );
  }

  return (
    <MainLayout scrollContainerRef={listRef} onScroll={hasAllFlashcardsDecks ? handleScroll : undefined}>
      <CardsContainer>
        {!hasAllFlashcardsDecks && (
          <Header>
            <Title font="title_4_bold_24">Demo mode</Title>
            <Button size="small" variant={isWidthSm ? 'secondary' : 'primary'} onClick={openUpgradeSubscriptionModal}>
              Upgrade
            </Button>
          </Header>
        )}
        {isShowMarkedForReCheckingPack && (
          <Flashcard
            onClick={() => {
              Boolean(markedForReCheckingCount) && navigate(routes.flashcards);
            }}
            name="Marked for re-checking"
            disabled={Boolean(!markedForReCheckingCount)}
            learnedCount={markedForReCheckingCount || 0}
          />
        )}
        {flashcardsPacks?.map((pack, index) => {
          let disabled;
          if (!isInstallment) {
            disabled = hasAllFlashcardsDecks && !isPause ? false : index > 0;
          } else {
            disabled = hasAllFlashcardsDecks && !isPauseCertificate ? false : index > 0;
          }

          return (
            <Flashcard
              disabled={disabled}
              onClick={disabled ? openUpgradeSubscriptionModal : openFlashcardsPack}
              showIcon={disabled}
              key={pack.id}
              {...pack}
            />
          );
        })}
        {isPacksLoading && <ThreeDots color={colors.primary[3]} />}
      </CardsContainer>
      <UpgradeSubscriptionModal
        // title="To get access to all decks of flashcards, purchase silver or gold subscription" //todo
        isOpen={isUpgradeSubscriptionModalOpen}
        onClose={closeUpgradeSubscriptionModal}
        manualType={ManualTypeEnum.FlashcardsPageUpgrade}
      />
    </MainLayout>
  );
};

export default FlashcardsPacks;

const CardsContainer = styled.div`
  width: 100%;
  max-width: 1224px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: fit-content;
  overflow: hidden;

  margin: 0 auto;

  padding: 28px 40px 40px 0;

  ${respondToWidth.sm`
      gap: 16px;
      padding: 24px 12px 24px 16px ;
  `}
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
`;

const Header = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;

  button {
    width: fit-content;
    padding-inline: 32px;
  }
`;

const Title = styled(StyledText)``;
